import React, { createContext, useContext, useState, useEffect } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import JSZip from "jszip";

const AvatarAssetsContext = createContext();

export const AvatarAssetsProvider = ({ children }) => {
  const [sofiaModel, setSofiaModel] = useState(null);
  const [animations, setAnimations] = useState([]);
  const [assetsLoaded, setAssetsLoaded] = useState(false); // New state for tracking loading completion


  useEffect(() => {
    const loadZipFiles = async () => {
      try {
        console.log("Loading Sofia model and animations...");

        // Load Sofia zip model
        const sofiaZip = await fetch("/models/sofia.zip");
        const sofiaData = await sofiaZip.arrayBuffer();
        const sofiaJSZip = await JSZip.loadAsync(sofiaData);
        const sofiaFile = Object.keys(sofiaJSZip.files).find((file) =>
          file.endsWith(".glb")
        );
        const sofiaBlob = await sofiaJSZip.files[sofiaFile].async("blob");
        const sofiaModel = await new GLTFLoader().loadAsync(
          URL.createObjectURL(sofiaBlob)
        );
        setSofiaModel(sofiaModel);

        // Load Animations zip
        const animationsZip = await fetch("/models/animations9.zip");
        const animationsData = await animationsZip.arrayBuffer();
        const animationsJSZip = await JSZip.loadAsync(animationsData);
        const animationsFile = Object.keys(animationsJSZip.files).find((file) =>
          file.endsWith(".glb")
        );
        const animationsBlob = await animationsJSZip.files[
          animationsFile
        ].async("blob");
        const animationsModel = await new GLTFLoader().loadAsync(
          URL.createObjectURL(animationsBlob)
        );

        setAnimations(animationsModel.animations);

        setAssetsLoaded(true);

        console.log("Sofia model and animations loaded!");
      } catch (error) {
        console.error("Error loading zip files:", error);
      }
    };

    loadZipFiles();
  }, []);

  return (
    <AvatarAssetsContext.Provider value={{ sofiaModel, animations, assetsLoaded, setAssetsLoaded   }}>
      {children}
    </AvatarAssetsContext.Provider>
  );
};

// export const useAvatarAssets = () => useContext(AvatarAssetsContext);
export const useAvatarAssets = () => {
  const context = useContext(AvatarAssetsContext);
  if (!context) {
    throw new Error('useAvatarAssets must be used within an AvatarAssetsProvider');
  }
  return context;
};
