

import { useAnimations, useGLTF } from "@react-three/drei";
import { useEffect, useRef, useState } from "react";
import Config, { PYTHON_URL } from "../Services/Config";
// import { TextureLoader } from "three";
import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import JSZip from 'jszip';

import { Canvas } from '@react-three/fiber';
import { useAvatarAssets } from "./AvatarAssetsContext";



export function Avatar(props) {
  let { showAvatar, onLoad , latestMsg, isWidgetOpen ,  cameraControls , taganimation} = props; // Receiving isWidgetOpen prop

  // const [model, setModel] = useState(null);
  // const [animations, setAnimations] = useState(null);
  // const { model } = useGLTF(`/models/sofia.glb`, (error) => {
  //   console.error("Failed to load model", error);
  // });
  // const { animations } = useGLTF(`/models/animations9.glb`);

  // const { nodes, materials } = useGLTF("/models/sofia.glb");
  // const { animations1 } = useGLTF("/models/animations9.glb");


  
    // const { model } = useGLTF("/models/sofia.glb");
    // const { animations } = useGLTF("/models/animations9.glb");

  // const { model, animations } = useAvatarAssets();
  const { sofiaModel: model, animations } = useAvatarAssets(); // Fix: Correctly destructuring sofiaModel as model

  // const [model, setModel] = useState(cachedData.sofiaModel);
  // const [animations, setAnimations] = useState(cachedData.animations);
  const group = useRef();
  const [scale, setScale] = useState(1);
  const [animation, setAnimation] = useState(
    animations.find((a) => a.name === "Idle") ? "Idle" : animations[0]?.name || ""
    // "waving"
  );
  // const { nodes, materials } = useGLTF("/models/64f1a714fe61576b46f27ca2.glb");
  useEffect(() => {
    if (cameraControls.current) {
      
      // This will ensure the camera is set as per your desired lookAt on Avatar load
      // cameraControls.current.setLookAt(0, 2, 5, 0, 1.5, 0);
      // cameraControls.current.setLookAt(0, 0, 0, 0, 0, 0);
    }else{
    }
  }, [cameraControls]);

  useEffect(() => {
    // Simulate avatar loading
    const loadAvatar = setTimeout(() => {
      if (onLoad) onLoad(); // Trigger the onLoad callback
    }, 1000); // Simulate 1-second loading time

    return () => clearTimeout(loadAvatar); // Cleanup timeout
  }, [onLoad]);

  
  //const { nodes, materials } = useGLTF(`/models/64f1a714fe61576b46f27ca2.glb?v=${version}`, (error) => {
  // const { nodes, materials } = useGLTF(`/models/sofia.glb`, (error) => {
  //   console.error("Failed to load model", error);
  // });
  // const { nodes, materials } = useGLTF(`/models/sofia.glb`, (error) => {
  //   console.error("Failed to load model", error);
  // });

 

  // const gltf = useGLTF(`/models/char1.glb`, (error) => {
  //   console.error("Failed to load model", error);
  // });
  // Load the model without textures
  // const nodes = useLoader(GLTFLoader, "/models/char2.glb");

  // const { animations } = useGLTF(`/models/animations9.glb`);
  // const { animations } = useGLTF(`/models/talking.glb`);
  // const group = useRef();
  const { actions, mixer } = useAnimations(animations, group);
  // const [animation, setAnimation] = useState(
  //   animations.find((a) => a.name === "talking_2") ? "talking_2" : animations[0]?.name || ""
  //   // "waving"
  // );
  // const [animation, setAnimation] = useState(
  //   animations.length > 0 ? animations[0].name : "" // Set the first animation's name if available
  // );

  // const [scale, setScale] = useState(1);
  
  
  
  useEffect(() => {
    if (actions[animation]) {
      const action = actions[animation];
      action.reset().fadeIn(0.5).play();
      
      return () => {
        // action.fadeOut(0.5);
        actions[animation]?.fadeOut(0.5);

      };
    }
  }, [animation, actions]);

  
  // Handle window resizing
  useEffect(() => {
    
    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;

      // Adjust scale based on window size but within a defined range
      const minScale = 0.5; // Minimum scale
      const maxScale = 1.5; // Maximum scale
      const idealWidth = 1200; // Width to base the scaling on
      const idealHeight = 800; // Height to base the scaling on

      const newScale = Math.max(
        minScale,
        Math.min(
          maxScale,
          Math.min(width / idealWidth, height / idealHeight)
        )
      );
      setScale(newScale);
    };

    // Set initial scale
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);
    
    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const playAudio =  () => {
    
      if (taganimation) {
        console.log("animations",animations);
        
        console.log("taganimation",taganimation);

        try {
         
          setAnimation(taganimation);
          
        

          setTimeout(() => {
            
            setAnimation("Idle");
          }, 2000);

        } catch (error) {
          console.error('Error playing audio:', error);
        }
        
      
      }
    };

    playAudio();

    return () => {
      // Any necessary cleanup
    };
  }, [taganimation,latestMsg, isWidgetOpen]); // Dependency on isWidgetOpen
  

  useEffect(() => {
    if (actions[animation]) {
      try {
        const action = actions[animation];
        action.reset().fadeIn(mixer.stats.actions.inUse === 0 ? 0 : 0.5).play();

        return () => {
          action.fadeOut(0.5);
        };
      } catch (error) {
        console.error('Error setting up animation:', error);
      }
    } else {
      console.warn(`Animation action "${animation}" is not defined.`);
    }
  }, [animation, actions, mixer]);

  //  // Load zip files using JSZip
  //  useEffect(() => {
    
  //   const loadZipFiles = async () => {
  //     try {
  //       if (!cachedData.sofiaModel || !cachedData.animations) {

  //         // Load Sofia zip model
  //         const sofiaZip = await fetch("/models/sofia.zip");
  //         const sofiaData = await sofiaZip.arrayBuffer();
  //         const sofiaJSZip = await JSZip.loadAsync(sofiaData);
  //         const sofiaFile = Object.keys(sofiaJSZip.files).find((file) =>
  //           file.endsWith(".glb")
  //         );
  //         const sofiaBlob = await sofiaJSZip.files[sofiaFile].async("blob");
  //         const sofiaModel = await new GLTFLoader().loadAsync(URL.createObjectURL(sofiaBlob));
  //         setModel(sofiaModel);
  //         console.log("sofiaModel",sofiaModel);
          
  //         // Load Animations zip
  //         const animationsZip = await fetch("/models/animations9.zip");
  //         const animationsData = await animationsZip.arrayBuffer();
  //         const animationsJSZip = await JSZip.loadAsync(animationsData);
  //         const animationsFile = Object.keys(animationsJSZip.files).find((file) =>
  //           file.endsWith(".glb")
  //         );
  //         const animationsBlob = await animationsJSZip.files[animationsFile].async("blob");
  //         const animationsModel = await new GLTFLoader().loadAsync(URL.createObjectURL(animationsBlob));

  //         // Set models and animations to state
  //         setModel(sofiaModel);
  //         setAnimations(animationsModel.animations);

  //         // Cache models and animations
  //         cachedData.sofiaModel = sofiaModel;
  //         cachedData.animations = animationsModel.animations;
  //     }
  //     } catch (error) {
  //       console.error("Error loading zip files:", error);
  //     }
  //   };

  //   loadZipFiles();
  // }, []); // Run once when the component mounts



  if (!model || !animations) {
    return null; // Loading state, you can add a loading spinner here
  }
return (
  <>
    {showAvatar && model && model.scene && (
      <group {...props} dispose={null} ref={group} scale={[scale, scale, scale]} position={[0, 0, 0]}>
        <primitive object={model.scene} />
        {/* <primitive object={nodes.scene} /> */}

      </group>
    )}
  </>
);

  // return (
  //       <group {...props} dispose={null} ref={group} scale={[scale, scale, scale]} position={[0, 0, 0]}>
  //         {/* <primitive object={nodes.Hips} /> */}
  //         {/* <primitive object={nodes.rp_sophia_animated_003_idling} /> */}
  //         <primitive object={model.scene} />
  //           {/* {gltf.scene.children.map((child, index) => (
  //             <mesh
  //               key={index}
  //               geometry={child.geometry}
  //               position={child.position}
  //               rotation={child.rotation}
  //               scale={child.scale}
  //             >
            
  //               <meshStandardMaterial map={texture} normalMap={texture} />
  //             </mesh>
  //           ))} */}
            
  //         {/* <skinnedMesh
  //           name="helloo"
  //           geometry={nodes.helloo.geometry}
  //           material={materials.hello}
  //           skeleton={nodes.helloo.skeleton}
  //         /> */}
  //         {/* <skinnedMesh
  //           name="Wolf3D_Body"
  //           geometry={nodes.Wolf3D_Body.geometry}
  //           material={materials.Wolf3D_Body}
  //           skeleton={nodes.Wolf3D_Body.skeleton}
  //         />
  //         <skinnedMesh
  //           name="Wolf3D_Outfit_Bottom"
  //           geometry={nodes.Wolf3D_Outfit_Bottom.geometry}
  //           material={materials.Wolf3D_Outfit_Bottom}
  //           skeleton={nodes.Wolf3D_Outfit_Bottom.skeleton}
  //         />
  //         <skinnedMesh
  //           name="Wolf3D_Outfit_Footwear"
  //           geometry={nodes.Wolf3D_Outfit_Footwear.geometry}
  //           material={materials.Wolf3D_Outfit_Footwear}
  //           skeleton={nodes.Wolf3D_Outfit_Footwear.skeleton}
  //         />
  //         <skinnedMesh
  //           name="Wolf3D_Outfit_Top"
  //           geometry={nodes.Wolf3D_Outfit_Top.geometry}
  //           material={materials.Wolf3D_Outfit_Top}
  //           skeleton={nodes.Wolf3D_Outfit_Top.skeleton}
  //         />
  //         <skinnedMesh
  //           name="Wolf3D_Hair"
  //           geometry={nodes.Wolf3D_Hair.geometry}
  //           material={materials.Wolf3D_Hair}
  //           skeleton={nodes.Wolf3D_Hair.skeleton}
  //         />
  //         <skinnedMesh
  //           name="EyeLeft"
  //           geometry={nodes.EyeLeft.geometry}
  //           material={materials.Wolf3D_Eye}
  //           skeleton={nodes.EyeLeft.skeleton}
  //           morphTargetDictionary={nodes.EyeLeft.morphTargetDictionary}
  //           morphTargetInfluences={nodes.EyeLeft.morphTargetInfluences}
  //         />
  //         <skinnedMesh
  //           name="EyeRight"
  //           geometry={nodes.EyeRight.geometry}
  //           material={materials.Wolf3D_Eye}
  //           skeleton={nodes.EyeRight.skeleton}
  //           morphTargetDictionary={nodes.EyeRight.morphTargetDictionary}
  //           morphTargetInfluences={nodes.EyeRight.morphTargetInfluences}
  //         />
  //         <skinnedMesh
  //           name="Wolf3D_Head"
  //           geometry={nodes.Wolf3D_Head.geometry}
  //           material={materials.Wolf3D_Skin}
  //           skeleton={nodes.Wolf3D_Head.skeleton}
  //           morphTargetDictionary={nodes.Wolf3D_Head.morphTargetDictionary}
  //           morphTargetInfluences={nodes.Wolf3D_Head.morphTargetInfluences}
  //         />
  //         <skinnedMesh
  //           name="Wolf3D_Teeth"
  //           geometry={nodes.Wolf3D_Teeth.geometry}
  //           material={materials.Wolf3D_Teeth}
  //           skeleton={nodes.Wolf3D_Teeth.skeleton}
  //           morphTargetDictionary={nodes.Wolf3D_Teeth.morphTargetDictionary}
  //           morphTargetInfluences={nodes.Wolf3D_Teeth.morphTargetInfluences}
  //         /> */}
  //       </group>
     
  // );
}

// useGLTF.preload("/models/sofia.glb");
// useGLTF.preload("/models/animations9.glb");
// useGLTF.preload("/models/sofia.zip");
// useGLTF.preload("/models/animations9.zip");
// useGLTF.preload("/models/sofia.zip");
// useGLTF.preload("/models/animations9.zip");