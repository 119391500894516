import { colors } from "./config";
const isMobile = /Mobi|Android/i.test(navigator.userAgent);

export const styles = {
   chatWidget: {
       // Position
       position: "fixed",
       bottom: "20px",
       right: "20px",
       backgroundColor: colors.primary,
       // Padding
       paddingLeft: "18px",
       paddingRight: "18px",
       paddingTop: "7px",
       paddingBottom: "7px",
       // Border
       borderRadius: "10px",
       cursor: "pointer",
   },

   chatWidgetText: {
    color: "white",
    fontSize: "15px",
    marginLeft: "5px",
  },
 
 
 // Styling for model window 
   modalWindow: {
     // Position
     position: "fixed",
    //  bottom: isMobile ? "270px" : "370px",  // Conditional bottom based on isMob

    //  bottom: window.innerWidth > 700 ? "240px" : "70px",
     bottom: isMobile ? (window.innerWidth > 700 ? "240px" : "210px") : "225px",  // Conditional bottom based on isMob

     zIndex: 1000,
     right: "20px",
     // Size
    //   width:"370px",
    //  // width: "420px",
    //  height: "50vh",
     width: window.innerWidth > 700 ? "370px" : "90%",  // Use full width for small screens
     height: window.innerWidth > 700 ? "50vh" : "40vh", // Adjust height for small screens
    //  maxWidth:  "300px",
     maxWidth: window.innerWidth > 300 ? "300px" : "100%", // Ensure full width on small screens

     maxHeight: "calc(100% - 48px)",
     backgroundColor: 'rgba(255, 255, 255, 0.7)',
     // Border
     borderRadius: "12px",
     border: `2px solid #bb0129`,
     overflow: "hidden",
     // Shadow
     boxShadow: "0px 0px 16px 6px rgba(0, 0, 0, 0.33)",
   },

}