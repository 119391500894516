/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import "./Property.css";
// import "./Profile.css";
import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { DatePicker, Button } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import Config from "../Services/Config";
import Footer from "../Footer/Footer";
import { useAuth } from "../AuthContext";
import LoginPopup from "../LoginSignup/LoginPopup";
import SignupPopup from "../LoginSignup/SignupPopup";
import { useNavigate } from "react-router-dom";
import NavbarWithObserver from "../NavBar/NavBar";
import Drawer from "@mui/material/Drawer";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import SnackbarComponent from "../SnackBar/SnackBar";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import { Button as MuiButton } from "@mui/material";
import IosShareIcon from '@mui/icons-material/IosShare';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import MessageIcon from '@mui/icons-material/Message';
import TwitterIcon from '@mui/icons-material/Twitter';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import { IMAGES_URL } from "../Services/Config";
import MapComponentView from "../SearchBar/MapsView";
import IconButton from '@mui/material/IconButton';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import TextField from '@mui/material/TextField';
// import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import {
  Typography,
  Card,
  CardContent,
  Grid,
  Divider,
  Paper,
} from "@mui/material";
import { nn } from "date-fns/locale";
import { useLanguage } from "../LanguageContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const { RangePicker } = DatePicker;

const emails = ['username@gmail.com', 'user02@gmail.com'];

function Property() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const propertyId = searchParams.get("id");
  const [propertyDetails, setPropertyDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [disabledDates, setDisabledDates] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedDays , setSelectedDays] = useState(null);
  const [selectedPrice , setSelectedPrice] = useState(0);
  const [selectedFees , setSelectedFees] = useState(0);
  const [selectedTotal , setSelectedTotal] = useState(0);
  const [userLocation, setUserLocation] = useState(null);
  const [distance, setDistance] = useState(null);
  const hostInformationRef = useRef(null);
  const googleMapsRef = useRef(null);
  const [isShowMoreOpen, setIsShowMoreOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [signupOpen, setSignupOpen] = useState(false);
  const { isAuthenticated, logout, userDetails } = useAuth();

  const { language, setLanguage } = useLanguage();

  const handleSetLang = (lang) => {
    setLanguage(lang);    
  }

  const navigate = useNavigate();
  const [state, setState] = React.useState({
    showmore: false,
    imageDrawer: false,
    selectedImage: null,
  });
  const [columns, setColumns] = useState(2);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('md');
  const [isLinkCopied, setIsLinkCopied] = React.useState(false);
  const [openShareDialog, setOpenShareDialog] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);
  const [initialTop, setInitialTop] = useState(null);
  const [initialBottom, setInitialBottom] = useState(null);

  const [fees, setFees] = useState(0);

  
  const [openDialog, setOpenDialog] = React.useState(false);
  const [hostId,setHostId] = React.useState(0);
  const [hostName,setHostName] = React.useState("");
  const [AllMsgs, setAllMsgs] = useState([]);

  const showAmenitiesCount = 3;
  const visibleAmenities = propertyDetails && propertyDetails.amenities
  // ? propertyDetails.amenities.slice(0, showAmenitiesCount)
  ? propertyDetails.amenities.slice(0,showAmenitiesCount)
  : [];




  const handleSetOpenDialog = (ownerId) => {
      if(isAuthenticated){
        setHostId(ownerId);
        setHostName(propertyDetails.owner.name);
        setOpenDialog(true);  // Now it accepts a value to open or close
        
      }else {
        handleLoginOpen();
      }
      
  }

  const handleSetCloseDialog = (value) => {
      setOpenDialog(false);
      setHostId(0);
      setHostName("");

  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isDateDisabled = (date) => {
    const dateString = dayjs(date).format("YYYY-MM-DD");
    const today = dayjs();

    const isReservedDate = disabledDates.includes(dateString);

    if (isReservedDate || dayjs(date).isBefore(today, "day")) {
      return true;
    }

    if (selectedStartDate) {
      const firstReservedDayAfterSelected = disabledDates.find((disabledDate) =>
        dayjs(disabledDate).isAfter(selectedStartDate)
      );

      if (firstReservedDayAfterSelected) {
        return dayjs(date).isAfter(dayjs(firstReservedDayAfterSelected), "day");
      }
      return false;
    }

    return false;
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1200) {
        setColumns(4);
      } else {
        setColumns(2);
      }
    };
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchPropertyDetails = async () => {
      try {
        const data = await Config.getPropertyDetails(propertyId);
        // console.log("data Property Details : ",data);
        setPropertyDetails(data);
        if (data && data.owner_id) {
          if(isAuthenticated){
            const fetchedMessages = await Config.getallmessages(data.owner_id);
            setAllMsgs(fetchedMessages);
          }
          

        }
        // console.log("reservation dates : ",data.reservationDates);

        setDisabledDates(
          data.reservationDates.flatMap(([startDate, endDate]) => {
            const start = moment(startDate);
            const end = moment(endDate);
            const dates = [];
            while (start.isSameOrBefore(end)) {
              dates.push(start.format("YYYY-MM-DD"));
              start.add(1, "day");
            }
            return dates;
          })
        );

        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    const fetchFees = async () => {
      try {
        const data = await Config.getFees();
        // console.log("data Property Details : ",data);
        // setPropertyDetails(data);
        setFees(data);

      } catch (error) {
        // setError(error);
      }
    };
    
    // console.log("geoLocation",navigator.geolocation);

    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(
    //     (position) => {
    //       const userLat = position.coords.latitude;
    //       const userLng = position.coords.longitude;

    //       console.log("geo location",position.coords);

    //       // setUserLocation({ lat: userLat, lng: userLng });

    //       const propertyDistance = calculateDistance(
    //         userLat,
    //         userLng,
    //         propertyDetails.latitude,
    //         propertyDetails.longitude
    //       );
    //       setDistance(propertyDistance);
    //     },
    //     (error) => {
    //       console.error("Error getting user location:", error);
    //     }
    //   );
    // } else {
    //   console.log("Geolocation is not supported by this browser.");
    // }
    fetchFees();
    fetchPropertyDetails();
    
  }, [propertyId, propertyDetails.latitude, propertyDetails.longitude]);

  useEffect(() => {
    if (!loading) {
      // Delay execution by 1 second
      const timer = setTimeout(() => {
        const scrollableDiv = document.querySelector('.scroll-with-mouse');

        if (scrollableDiv) {
          const rect = scrollableDiv.getBoundingClientRect();
          const initialTop1 = rect.top + window.scrollY;
          const initialBottom1 = rect.bottom + window.scrollY;

          setInitialTop(initialTop1);
          setInitialBottom(initialBottom1);
          console.log('Initial Top:', initialTop1);
          console.log('Initial Bottom:', initialBottom1);
        } else {
          console.log('Element .scroll-with-mouse not found!');
        }
      }, 500); // 0.5-second delay

      // Cleanup timeout on unmount or loading change
      return () => clearTimeout(timer);
    }
  }, [loading]); // Run when loading changes to false
  // useEffect(() => {
  //   const handleScroll = (e) => {
      
  //     // console.log("scroll ",e);
  //     const scrollPosition = window.scrollY; // or document.documentElement.scrollTop
  //     console.log('Scroll position (Y):', scrollPosition);
  //     const scrollableDiv = document.querySelector('.scroll-with-mouse');
  //     const propertyDetails = document.querySelector('.property-details');
  //     // const googleMaps = document.querySelector('.google-maps');
  //     const stopscrolling = document.querySelector('.stopscrolling');
  //     // console.log("scrollableDiv",scrollableDiv);
      
  //     if (scrollableDiv && propertyDetails && stopscrolling ) {
  //       // e.preventDefault();
  //       const currentMarginTop = parseFloat(scrollableDiv.style.marginTop) || 0;
  //       // Update the marginTop with deltaY
  //       const newMarginTop = currentMarginTop + e.deltaY;
  //       // console.log("currentMarginTop",currentMarginTop);
  //       // console.log("newMarginTop",newMarginTop);

  //       // Get the bounding rectangles of the reference elements
  //       const propertyDetailsBottom = propertyDetails.getBoundingClientRect().bottom;
  //       const stopscrollingTop = stopscrolling.getBoundingClientRect().top;
  //       const scrollableDivHeight = scrollableDiv.offsetHeight;
  //       // Get the current position of the scrollableDiv
  //       const scrollableDivTop = scrollableDiv.getBoundingClientRect().top;
  //       const scrollableDivBottom = scrollableDivTop + scrollableDivHeight;
  //       // console.log("propertyDetailsBottom",propertyDetailsBottom);
  //       console.log("stopscrolling",stopscrollingTop);
  //       // console.log("scrollableDivHeight",scrollableDivHeight);
  //       console.log("scrollableDivTop",scrollableDivTop);
  //       // console.log("scrollableDivBottom",scrollableDivBottom);
  //       console.log("initialTop",initialTop,"initialBottom",initialBottom);
        
  //       if((scrollPosition + 150 ) < initialTop){
  //         console.log("prevent");
          
  //         return;
  //       }
  //       if(scrollableDivTop < 200 ){
  //         console.log("start down");
         
  //         const min = stopscrollingTop - scrollableDivTop ;
  //         console.log("min",min);
          
  //         if( min > -100 && min < 100){
  //           console.log("prevent");
          
  //           return;
  //         }
  //         scrollableDiv.style.marginTop = `${newMarginTop}px`;
  //       }
        

       
        
  //     }
  //   };
  
  //   window.addEventListener('wheel', handleScroll, { passive: false });
  
  //   return () => {
  //     window.removeEventListener('wheel', handleScroll);
  //   };
  // }, [initialTop,initialBottom]);
  

  const calculateDistance = (userLat, userLng, propertyLat, propertyLng) => {
    const earthRadius = 6371;

    const dLat = toRadians(propertyLat - userLat);
    const dLng = toRadians(propertyLng - userLng);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(userLat)) *
      Math.cos(toRadians(propertyLat)) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = earthRadius * c;

    return distance;
  };

  const toRadians = (degrees) => {
    return degrees * (Math.PI / 180);
  };

  if (loading) {
    return (
      <div className="Rent">
        <p>Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="Rent">
        <p>Error: {error.message}</p>
      </div>
    );
  }
  function SimpleDialog(props) {
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
      onClose(selectedValue);
    };

    const [openSnack, setSnack] = React.useState(false);

    const handleCloseSnackBar = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }

      setSnack(false);
    };

    return (
      <Dialog
        onClose={handleClose}
        open={open}
        classes={{ paper: 'custom-dialog-paper' }}
      >
        <DialogTitle style={{ textAlign: "center", fontSize: "1.75rem" }}>Share this place</DialogTitle>
        <div className="all-buttons" style={{ display: "flex" }}>

          <div className="left-column">

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <MuiButton
                variant="outlined"
                className="wewbtn"
                // onClick={handleSnackBarClick}
                style={{ color: "rgb(44, 44, 44)", borderColor: "rgb(179, 179, 179)", marginBottom: "10%", fontSize: "1rem", borderRadius: "10px" }}>
                <ContentCopyIcon />
                Copy link
              </MuiButton>
            </Grid>

            <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnackBar}>
              <Alert onClose={handleCloseSnackBar} severity="success" sx={{ width: '100%' }}>
                Link successfully copied!
              </Alert>
            </Snackbar>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <MuiButton variant="outlined" className="wewbtn" style={{ color: "rgb(44, 44, 44)", borderColor: "rgb(179, 179, 179)", marginBottom: "10%", fontSize: "1rem", borderRadius: "10px" }}>
                <WhatsAppIcon style={{ color: "rgb(0, 210, 0)" }} />
                Whatsapp
              </MuiButton>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <MuiButton variant="outlined" className="wewbtn" style={{ color: "rgb(44, 44, 44)", borderColor: "rgb(179, 179, 179)", marginBottom: "10%", fontSize: "1rem", borderRadius: "10px" }}>
                <MessageIcon style={{ color: "green" }} />
                Messages
              </MuiButton>
            </Grid>

          </div>

          <div className="right-column">
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <MuiButton variant="outlined" className="wewbtn" style={{ color: "rgb(44, 44, 44)", borderColor: "rgb(179, 179, 179)", marginBottom: "10%", fontSize: "1rem", borderRadius: "10px" }}>
                <EmailIcon />
                E-mail
              </MuiButton>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <MuiButton variant="outlined" className="wewbtn" style={{ color: "rgb(44, 44, 44)", borderColor: "rgb(179, 179, 179)", marginBottom: "10%", fontSize: "1rem", borderRadius: "10px" }}>
                <FacebookIcon style={{ color: "blue" }} />
                Facebook
              </MuiButton>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <MuiButton variant="outlined" className="wewbtn" style={{ color: "rgb(44, 44, 44)", borderColor: "rgb(179, 179, 179)", marginBottom: "10%", fontSize: "1rem", borderRadius: "10px" }}>
                <TwitterIcon style={{ color: "rgb(0, 110, 255)" }} />
                Twitter
              </MuiButton>
            </Grid>
          </div>

        </div>
      </Dialog>
    );
  }

  SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
  };

  const handleClickOpenShareDialog = () => {
    setOpenShareDialog(true);
  };

  const handleCloseShareDialog = (value) => {
    setOpenShareDialog(false);
    setSelectedValue(value);
  };

  const {
    name,
    location: propertyLocation,
    available_at: propertyAvailability,
    availability_ends_at: propertyAvailabilityEnd,
    distance: propertyDistance,
    short_description: propertyShortDescription,
    rating: propertyRating,
    property_images: propertyImages,
    description: propertyDescription,
    owner_id: ownerId,
    category_id: categoryId,
    type_id: typeId,
    occupancy_status_id: occupancyStatusId,
    price,
    latitude,
    longitude,
    num_bedrooms: numBedrooms,
    num_guests: numGuests,
    num_beds: numBeds,
    dedicated_bathroom: hasDedicatedBathroom,
    private_bathroom: hasPrivateBathroom,
    shared_bathroom: hasSharedBathroom,
    min_duration: minDuration,
    instant_booking: instantBooking,
    reviews,
    rent_buy:rentBuy,
  } = propertyDetails;

  const toggleShowMore = () => {
    setIsShowMoreOpen(!isShowMoreOpen);
  };

  const switchToSignup = () => {
    handleLoginClose();
    // handleSignupOpen();
  };

  const switchToLogin = () => {
    handleSignupClose();
    handleLoginOpen();
  };

  const handleLoginOpen = () => {
    setLoginOpen(true);
    setSignupOpen(false);
  };

  const handleLoginClose = () => {
    setLoginOpen(false);
    // console.log("close login");
  };

  // const handleSignupOpen = () => {
  //   setSignupOpen(true);
  //   setLoginOpen(false);
  // };

  const handleSignupClose = () => {
    setSignupOpen(false);
  };

  function handleReserve() {
    let token = localStorage.getItem('token');
    // console.log(token)
    if (token !== null) {
      if ((selectedEndDate == null || selectedStartDate == null) && rentBuy=="0") {
        setOpenSnackbar(true);
        setSnackbarMessage('Please select a Start date and End date');
        setSnackbarSeverity("error");
      } else {
        
        navigate('/reservation', { state: { propertyId: propertyId, checkIn: (rentBuy=="0")?formatDateYMD(selectedStartDate):null, checkOut: (rentBuy=="0")?formatDateYMD(selectedEndDate):null } });
      }
    } else {
      setLoginOpen(true)
    }
  }

  const formatDateYMD = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const toggleDrawer = (anchor, open) => (event) => {
    event.preventDefault();

    setState({ ...state, [anchor]: open });
  };

  const toggleImageDrawer = (open, selectedImage = null) => {
    setState({ ...state, imageDrawer: open, selectedImage });
  };

  function srcset(image, size, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${size * rows
        }&fit=crop&auto=format&dpr=2 2x`,
    };
  }

  const handleImageClick = (image) => {
    setState({ ...state, showmore: true });
  };

  return (
    <div>
      <LoginPopup
        isOpen={loginOpen}
        onClose={handleLoginClose}
        onSwitchToSignup={switchToSignup}
      />
      <SignupPopup
        isOpen={signupOpen}
        onClose={handleSignupClose}
        onSwitchToLogin={switchToLogin}
      />

      <NavbarWithObserver  openDialog={openDialog}  handleSetOpenDialog={handleSetOpenDialog} handleSetCloseDialog={handleSetCloseDialog} hostId={hostId} hostName={hostName} hostMsg={AllMsgs} handleChangeLanguage={handleSetLang} />
      <div className="Rent">
        <main className="main-component">
         
          
          <Typography variant="h4" style={{ marginLeft: "3%" }}>
                    {' '}
                      <span
                        // onClick={() => {
                        //   hostInformationRef.current.scrollIntoView({
                        //     behavior: "smooth",
                        //   });
                        // }}
                        // className="host-name"
                      >
                      
                        {name}
                      </span>
                    </Typography>
          <Grid container className="myContainer-item">
            <div className="property-details">
              <Grid container style={{ width: '100%' }}   >
                <Grid item lg={6} md={6} sm={12} xs={12} className="large-picture" >
                  <img
                    src={IMAGES_URL + propertyImages[0].image_url}
                    alt="large-picture"
                    style={{ borderRadius: '8px',width: '100%'}} // Corrected syntax

                    onClick={handleImageClick}
                    
                  />

                </Grid>
                
                <Grid item lg={6} md={6} sm={12} xs={12}  className="small-pictures" >
                  {propertyImages.slice(1, 5).map((image, imgindex) => (
                    <Grid item lg={6} md={6} sm={6} xs={12} key={image.id || imgindex}>
                      <div className="small-picture">
                        <img
                        style={{ borderRadius: '8px', width: '100%'  , 
                                 marginBottom: '12px ',  // Adding margin between images
                                 marginRight: imgindex % 2 === 0 ? '12px ' : '0', // Right margin for alternate images }} // Corrected syntax
                              }}
                        src={IMAGES_URL + image.image_url}
                          alt={`Small Picture ${imgindex + 1}`}
                          onClick={handleImageClick}
                        />
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </Grid> 

              <div className="button-container">
                <div>
                  {["showmore"].map((anchor) => (
                    <React.Fragment key={anchor}>
                      <Button onClick={toggleDrawer(anchor, true)}>
                        Show More
                      </Button>
                      <Drawer
                        anchor="bottom"
                        open={state[anchor]}
                        onClose={() => toggleDrawer(anchor, false)}
                        PaperProps={{
                          style: {
                            // height: "100vh",
                          },
                        }}
                      >
                        <div>
                          <IconButton onClick={toggleDrawer('showmore', false)} style={{ marginTop: '0.5%', marginLeft: '1%' }}>
                            <CloseIcon />
                          </IconButton>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            marginBottom: "20px",
                          }}
                        >
                          <ImageList
                            style={{
                              width: "60%",
                              height: "100%",
                              margin: "auto",
                              overflow: 'hidden',
                            }}
                            variant="quilted"
                            cols={4}
                            rowHeight={121}
                          >
                            {propertyImages.map((item, index) => (
                              <ImageListItem
                                key={item.id}
                                cols={item.cols || columns}
                                rows={item.rows || 2}
                                onClick={() => toggleImageDrawer(true, item)}
                              >
                                <img
                                  {...srcset(
                                    IMAGES_URL + item.image_url,
                                    121,
                                    item.rows,
                                    item.cols
                                  )}
                                  alt={item.title}
                                  loading="lazy"
                                  className="imagelist"
                                />
                              </ImageListItem>
                            ))}
                          </ImageList>

                        </div>
                      </Drawer>

                    </React.Fragment>
                  ))}

                  <Drawer
                    anchor="bottom"
                    open={state.imageDrawer}
                    onClose={() => toggleImageDrawer(false)}
                    PaperProps={{
                      style: {
                        height: "100vh",
                      },
                    }}
                  >
                    <div>
                      <IconButton style={{ marginTop: '0.5%', marginLeft: '1%' }} onClick={() => toggleImageDrawer(false)}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100vh', // Adjust as needed
                      }}
                    >
                      <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={50}
                        slidesPerView={1}
                        navigation
                        pagination={{ clickable: true }}
                        scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                        style={{ width: '90%', height: '90vh' }}
                      >
                        {propertyImages.map((image, index) => (
                          <SwiperSlide key={index}>
                            <img
                              src={IMAGES_URL + image.image_url}
                              alt={`Slide ${index + 1}`}
                              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </Drawer>

                </div>
              </div>

              {isShowMoreOpen && (
                <div className="show-more-container">
                  {propertyImages.map((image, indexxx) => (
                    <div className="show-more-picture" key={indexxx}>
                      <img
                        src={IMAGES_URL + image.image_url}
                        alt={`Slide Up Picture ${indexxx + 1}`}
                        className="show-more-img"
                      />
                    </div>
                  ))}

                </div>
              )}
            </div>
            {/* ////////////////////////////////////////////////////////////////////////////////////// */}
            <Grid container className="AA">

              <Grid item className="" s={12} xs={12} md={12} lg={8}>
                <div className="property-description">
                  <div style={{ marginTop: "2%" }}>
                    <div className="title-img">
                    <Typography variant="h4" style={{ marginLeft: "3%" }}>
                      
                        <span >
                        
                          {propertyLocation}
                        </span>
                      </Typography>
                      {/* <Typography variant="h4" style={{ marginLeft: "3%" }}> WAS BY HOSTNAME BEFORE 14-11-2024
                      by{' '}
                        <span
                          onClick={() => {
                            hostInformationRef.current.scrollIntoView({
                              behavior: "smooth",
                            });
                          }}
                          className="host-name"
                        >
                        
                          {rentBuy=="0"?propertyDetails.owner.name:'admin'}
                        </span>
                      </Typography> */}
                      <div className="share">
                        <div>
                          <MuiButton
                            onClick={handleClickOpenShareDialog}
                            className="buttonContainer"
                            style={{ color: "black", borderColor: "black" }}
                            variant="outlined"
                          >
                            <IosShareIcon fontSize="small" style={{ marginRight: '2px', marginBottom: "2px" }} />
                            <span style={{ verticalAlign: 'middle' }}>share</span>
                          </MuiButton>
                          <SimpleDialog
                            selectedValue={selectedValue}
                            open={openShareDialog}
                            onClose={handleCloseShareDialog}
                          />
                        </div>
                      </div>
                    </div>
                    <p className="info">
                      {numGuests} guests <span>·</span> {numBedrooms} bedrooms{" "}
                      <span>·</span> {numBeds} beds <span>·</span>{" "}
                      {hasDedicatedBathroom} bathrooms{" "}
                    </p>
                  </div>

                  <Typography className="property-descriptions">
                    {" "}
                    {propertyDescription}
                  </Typography>

                  
                  
                  <div className="property-section">
                    <div className="divider"></div>
                  
                      <div className="profile-container">
                        <div className="profile-pic">
                          <img
                            src={IMAGES_URL + propertyDetails.owner.profile_pic}
                            className="profile-pic-img"
                            alt=""
                          />
                        </div>
                        <div className="host-name-joindate" >
                          <h2  >Hosted by {propertyDetails.owner.name}</h2>
                          <p className="joined">
                            Joined on{" "}
                            {moment(propertyDetails.owner.created_at).format("MMMM D, YYYY")}
                          </p>
                        </div>
                      </div>

                  
                    <div className="divider"></div>
                  </div>
                  <div className="property-section">
                  

                    <h2 className="subtitle">Additional Information</h2>
                    <div className="property-section-details">
                      <Typography className="">
                        - Type: {propertyDetails.type.name}
                      </Typography>
                      <Typography className="">
                        - Private Bathrooms: {hasPrivateBathroom}
                      </Typography>
                      <Typography className="">
                        - Shared Bathrooms: {hasSharedBathroom}
                      </Typography>
                      {rentBuy === "0" && ( <Typography className="">
                        - Minimum Durations: {minDuration}{" "}
                        {minDuration == 1 ? "Night" : "Nights"}
                      </Typography>
                      )}
                    </div>
                    <br/>
                    <div className="divider"></div>
                  </div>

                  <div className="property-section">
                    <h2 className="subtitle">Amenities</h2>
                    <div className="amenities-container">
                      {visibleAmenities.map((amenity) => (
                        <div key={amenity.id} className="amenity-item">
                          <img
                            src={`${IMAGES_URL}${amenity.icon}`}
                            alt={amenity.name}
                            className="amenity-icon"
                          />
                          <Typography className="amenity-name">{amenity.name}</Typography>
                        </div>
                      )
                      )
                      }
                      {propertyDetails.amenities.length > showAmenitiesCount && (
                        <React.Fragment>
                          <Button variant="outlined" onClick={handleClickOpen}>
                            Show all Amenities
                          </Button>
                          <Dialog
                            fullWidth={true}
                            maxWidth="md"
                            open={open}
                            onClose={handleClose}
                            keepMounted
                          >
                            <DialogTitle>Amenities</DialogTitle>
                            <DialogContent>
                              <div className="amenities-container">
                                {propertyDetails.amenities.map((amenity) => (
                                  <div key={amenity.id} className="amenity-item">
                                    <img
                                      src={`${IMAGES_URL}${amenity.icon}`}
                                      alt={amenity.name}
                                      className="amenity-icon"
                                    />
                                    <Typography className="amenity-name">{amenity.name}</Typography>
                                  </div>
                                ))}
                              </div>
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={handleClose}>Close</Button>
                            </DialogActions>
                          </Dialog>
                        </React.Fragment>
                      )}
                      <div className="divider stopscrolling"></div>
                    </div>
                  </div>
                </div>
              </Grid>

              <Grid
                item
                className="scroll-with-mouse"
                style={{
                  marginTop: "2%",
                  marginBottom: "2%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
                xs={12}
                s={12}
                md={12}
                lg={4}
              >
                <Paper className="pricecolw" elevation={5}>
                    <h3 className="frst-h3">
                    <div className="pricee">
                      <p >
                         ${price} 
                        {rentBuy === "1" ? null : <span className="small-text-price"> night</span>}
                      </p>
                    </div>
                    
                    {/* {rentBuy === "0" && (
                      <div className="n-reviews">
                        <p className="reviews">{reviews} review(s)</p>
                      </div>
                    )} */}
                  </h3>
                  <div className="pricecol"> 

                  {rentBuy === "0" && (
                    <div className="airbnb-range-picker">
                      <div className="custom-date-picker">
                        {/* Check-in Title */}
                        {/* <div className="custom-label">
                          {selectedStartDate ? `Check-in: ${selectedStartDate.format("YYYY-MM-DD")}` : "Check-in"}
                          {selectedEndDate ? `Check-out: ${selectedEndDate.format("YYYY-MM-DD")}` : "Check-out"}

                        </div> */}
                       
                        <RangePicker
                          style={{ borderColor: "beige" }}
                          separator=""
                          disabledDate={isDateDisabled}
                          localeText={{ start: 'Check-in', end: 'Check-out' }}
                          value={[selectedStartDate, selectedEndDate]} // Make the values controlled
                          onCalendarChange={(dates) => {
                            if (dates && dates.length > 0) {
                              setSelectedStartDate(dates[0]);
                              setSelectedEndDate(dates[1]);
                              console.log("dates",dates);
                              if(dates[0] == null || dates[1] == null){
                                return;
                              }

                              // Calculate the number of days between the start and end dates
                              const startDate = new Date(dates[0]);
                              const endDate = new Date(dates[1]);
                              // Calculate the difference in days
                              const differenceInTime = endDate - startDate;
                              const differenceInDays = differenceInTime / (1000 * 3600 * 24); // Convert milliseconds to days

                              setSelectedDays(differenceInDays);
                              // setSelectedPrice(differenceInDays * price);
                              const priceToSet = Math.round((differenceInDays * price) / 5) * 5;
                              
 
                              setSelectedPrice(priceToSet);
                              const feesRound = Math.round(priceToSet * fees); // Rounds to the nearest whole number
                              setSelectedFees(feesRound);
                              const total = Number(priceToSet) + Number(feesRound);
                              setSelectedTotal(total);


                            } else {
                              setSelectedStartDate(null);
                              setSelectedEndDate(null);
                              setSelectedDays(null);
                              setSelectedPrice(0);
                              setSelectedFees(0);
                              setSelectedTotal(0);
                            }
                          }}
                          // placeholder={["Select Check-in Date", "Select Check-out Date"]}
                        />
                        
                      </div>
                
                      
                    </div>
                    // <div className="airbnb-range-picker">
                    //   <RangePicker
                    //     style={{ borderColor: 'beige' }}
                    //     separator=""
                    //     disabledDate={isDateDisabled}
                    //     placeholder={[
                    //       selectedStartDate
                    //         ? `Check-in ${selectedStartDate.format("YYYY-MM-DD")}`
                    //         : "Check-in",
                    //       selectedEndDate
                    //         ? `Check-out ${selectedEndDate.format("YYYY-MM-DD")}`
                    //         : "Check-out",
                    //     ]}
                    //     onCalendarChange={(dates) => {
                    //       if (dates && dates.length > 0) {
                    //         setSelectedStartDate(dates[0]);
                    //         setSelectedEndDate(dates[1]);
                    //       } else {
                    //         setSelectedStartDate(null);
                    //         setSelectedEndDate(null);
                    //       }
                    //     }}
                    //   />
                    // </div>
                      // <RangePicker
                      //   style={{ borderColor: 'beige' }}
                      //   separator=""
                      //   disabledDate={isDateDisabled}
                      //   onCalendarChange={(dates) => {
                      //     if (dates && dates.length > 0) {
                      //       setSelectedStartDate(dates[0]);
                      //       setSelectedEndDate(dates[1]);
                      //     } else {
                      //       setSelectedStartDate(null);
                      //       setSelectedEndDate(null);
                      //     }
                      //   }}
                      // />
                    )}
                    {rentBuy === "0" && (
                        <p className="price-info">
                          <span>Minimum Days: {minDuration}</span>
                          {/* <span>{minDuration}</span> */}
                        </p>
                    )}
                    {/* {rentBuy === "0" && (
                        <p className="price-info">
                          <span>Occupancy status:</span>
                          <span>{occupancyStatusId}</span>
                        </p>
                    )}
                    {rentBuy === "0" && (
                      <p className="price-info">
                        <span>Instant booking:</span>
                        <span>{instantBooking}</span>
                      </p>
                    )} */}

              
                    {rentBuy=="0" &&(
                        <div>
                          <MuiButton
                            variant="contained"
                            style={{ backgroundColor: '#bb0129', width: '85%', height: '3rem', fontSize: '1rem' }}
                            onClick={handleReserve}
                          >
                            Reserve
                          </MuiButton>
                          <p className="charge">You will not be charged yet</p>
                          
                          {/* {selectedPrice && <p className="price-info">
                            <span>${price} x {selectedDays} {selectedDays === 1 ? 'Night' : 'Nights'}</span>
                            <span>${selectedPrice}</span>
                          </p>}
                          {selectedFeez && <p className="price-info">
                            <span>Divinests service fee</span>
                            <span>${selectedFeez}</span>
                          </p>}
                          {selectedTotal && <p className="price-info">
                            <span>Total: </span>
                            <span>${selectedTotal}</span>
                          </p>} */}
                          {selectedPrice > 0 && (
                            <p className="price-info">
                              <span>${price} x {selectedDays} {selectedDays === 1 ? 'Night' : 'Nights'}</span>
                              <span>${selectedPrice}</span>
                            </p>
                          )}
                          {selectedFees > 0 && (
                            <p className="price-info">
                              <span>Divinests service fee</span>
                              <span>${selectedFees}</span>
                            </p>
                          )}
                          {selectedTotal > 0 && (
                            <hr/>
                          )}
                          {selectedTotal > 0 && (
                            <p className="price-info">
                              <b style={{fontSize:"20px"}}>Total: </b>
                              <b>${selectedTotal}</b>
                            </p>
                          )}

                        </div>
                        
                    )}
                    </div>
          
                </Paper>
              </Grid>
            </Grid>
          </Grid>

          <div className="google-maps" ref={googleMapsRef}>
            <p className="where">Where you'll be:</p>
            <h2>{propertyLocation}</h2>
            {/* <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3938.814220113875!2d35.54769020779101!3d33.886183079954385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151f1726ee819c53%3A0x3051104fd76f3a2e!2sEddy&#39;s%20group!5e0!3m2!1sen!2slb!4v1699713352953!5m2!1sen!2slb"
              width="600"
              height="450"
              style={{ border: "0" }}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe> */}
            <div style={{ width: '100%', margin: '0 auto' }}>
              <MapComponentView latitude={latitude} longitude={longitude} />
            </div>
          </div>

        

          <div className="divider1"></div>
          {rentBuy=="0" &&(
          <Grid container>
            <Grid item lg={12} className="host-title">
           
              
            </Grid>
          </Grid>
        )}
          <Grid container className="host-information" ref={hostInformationRef} style={{ justifyContent: 'center', alignItems: 'center' }}>
            

           
            {rentBuy=="0" &&(
              
              <div className="contact-host">
                  <button
                    className="contact-host-btn"
                    onClick={() => handleSetOpenDialog(propertyDetails.owner_id)} // Pass owner_id here
                  >
                    Contact Host
                  </button>
              </div>
              )}
              {rentBuy === "1" && (
                  <div>
                    <Typography variant="h6" style={{ fontWeight: 'bold', fontSize: '24px', marginBottom: '10px' }}>
                      For more information about this property Contact US
                    </Typography>
                    <Typography style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '5px' }}>
                      E-mail: <a href="mailto:admin@hamroghar.com" style={{ color: '#1e90ff', textDecoration: 'none' }}>admin@hamroghar.com</a>
                    </Typography>
                    <Typography style={{ fontSize: '18px', fontWeight: 'bold' }}>
                      Phone: <a href="tel:+9613308530" style={{ color: '#1e90ff', textDecoration: 'none' }}>00961 3 308530</a>
                    </Typography>
                  </div>
                )}

           
              
            
          </Grid>
        </main>
      </div>

      <SnackbarComponent
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />


      <Footer />
    </div>
  );
}

export default Property;
