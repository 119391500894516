import React, { useState, useEffect, useRef } from "react";
import "./SearchBar.css";
import Filters from "./Filters/Filters";
import { Grid } from '@mui/material';
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { Button, DatePicker } from "antd";
import IconButton from "@mui/material/IconButton";
import Config from "../Services/Config";
import TuneIcon from "@mui/icons-material/Tune";
import SearchIcon from "@mui/icons-material/Search";
import { orange } from "@mui/material/colors";
import { IMAGES_URL, API_URL } from "../Services/Config";
import axios from "axios";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import GridViewIcon from '@mui/icons-material/GridView';
import {  Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createFilterOptions } from '@mui/material/Autocomplete';
import MapsComponent from "./MapsComponent";
import GoogleMapsAutocomplete from "./GoogleMapsAutocomplete";
import moment from 'moment';
import { ButtonGroup } from "react-bootstrap";



const { RangePicker } = DatePicker;
const filter = createFilterOptions();
const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: '15px',
    "&.Mui-focused fieldset": {
      borderColor: "blue",
    },
  },
  "& .MuiInputLabel-root": {
    color: "grey",
    "&.Mui-focused": {
      color: "blue",
    },
  },
});
const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    fontFamily: 'MMC Regular',
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  button: {
    fontFamily: 'MMC Regular',
    flex: 1,
    borderRadius: '15px',
    margin: theme.spacing(0.2), // Reduced margin between buttons
    padding: theme.spacing(0.5), // Adjusted padding for closer spacing
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      borderRadius: '10px',
      margin: theme.spacing(0.2, 0), // Adjusted margin for closer spacing on small screens
      padding: theme.spacing(0.5, 0), // Adjusted padding for closer spacing on small screens
    },
  },
  labelSelected: {
    color: 'white',
    fontFamily: 'MMC Regular',

    [theme.breakpoints.down('xs')]: {
      fontSize: 'medium',
    },
  },
  labelNotSelected: {
    color: 'black',
    fontFamily: 'MMC Regular',
    [theme.breakpoints.down('xs')]: {
      fontSize: 'medium',
    },
  },
  paper: {
    padding: '4px', // Keeping the reduced padding
    border: '1px solid #ccc',
    borderRadius: '10px',
    fontFamily: 'MMC Regular',
    margin: theme.spacing(0.5), // Keeping the added margin for spacing
    [theme.breakpoints.down('xs')]: {
      padding: '2px', // Keeping the further reduced padding for small screens
      margin: theme.spacing(0.5, 0), // Keeping the adjusted margin for small screens
    },
  },
}));

const ResponsiveButtonGroup = () => {
  const [selectedButton, setSelectedButton] = useState('all');
  const classes = useStyles();

  const handleButtonClick = (type) => {
    setSelectedButton(type);
  };}
function SearchBar({ onSearch }) {
  const [location, setLocation] = useState("");
  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const [guests, setGuests] = useState("");
  const [selectedButton, setSelectedButton] = useState('all');

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(1000);
  const [minRange, setMinRange] = useState(0);
  const [maxRange, setMaxRange] = useState(200);
  const [categories, setCategories] = useState([]);
  const [anchorElMenu, setAnchorElMenu] = React.useState(null);
  const [selectedPropertyType, setSelectedPropertyType] = useState(0);
  const [selectedBedroom, setSelectedBedroom] = useState("");
  const [selectedBeds, setSelectedBeds] = useState("");
  const [selectedBathroom, setSelectedBathroom] = useState("");
  const [long, setLongitude] = useState(35.5833);
  const [lat, setLatitude] = useState(33.95);
  const [deflt,setDefault] = useState(0);
  const [filtered,setFilter] = useState(0);

  const openMenu = Boolean(anchorElMenu);
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedCategory, setSelectedCategory] = useState(0);
  const isSmallScreen = useMediaQuery('(max-width:600px)');


  const disabledDate = (current) => {
    // Disable dates before today
    return current && current < moment().startOf('day');
  };


      
  // };
  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (long !== 0 && lat !== 0) {
          
          
          const searchResults = await Config.getHomeCardDetails(
            lat,
            long,
            selectedButton,
            checkIn,checkOut,
            minPrice,maxPrice,minRange,maxRange,
            selectedCategory,selectedPropertyType,
            selectedBedroom,selectedBeds,selectedBathroom,
            location,

        );
        
  
          // console.log("search results", searchResults);
          
          onSearch({
            location,
            checkIn,
            checkOut,
            guests,
            minPrice,
            maxPrice,
            maxRange,
            minRange,
            selectedCategory,
            selectedPropertyType,
            selectedBedroom,
            selectedBeds,
            selectedBathroom,
            long,
            lat,
            selectedButton,
          });
        } else {
          // console.log("long or lat is 0");
        }
      } catch (error) {
        console.error("Error during search:", error);
      }
    };
  
    if (selectedButton !== null) {
      // console.log("location",location);
      
      fetchData();
    }
  }, [selectedButton]); 
  useEffect(() => {
    const fetchData = async () => {
        try {
            
                // console.log("selectedButton", selectedButton);
                // Adjust this function call to your actual function
                const searchResults = await Config.getHomeCardDetails(
                  lat,
                   long,
                    selectedButton,
                    minPrice,maxPrice,minRange,maxRange,
                    selectedCategory,selectedPropertyType,
                    selectedBedroom,selectedBeds,selectedBathroom,
                    location,
                );

                // console.log("search results", searchResults);

                // Assuming you want to pass the search results to onSearch
                onSearch({
                    long,
                    lat,
                    selectedButton,
                    ...searchResults, // Add any other parameters as needed
                });
           
        } catch (error) {
            console.error("Error during search:", error);
        }
    };

    // Trigger fetchData when selectedButton changes and lat/long are valid
    if (selectedButton !== null && long !== 0 && lat !== 0) {
        fetchData();
    }
}, [selectedButton, long, lat]); // Include long and lat in dependency array

  const classes = useStyles();


  const scrollContainerRef = useRef(null);

  const handleScroll = (direction) => {
    const scrollAmount = 200; // Adjust as needed
    const container = scrollContainerRef.current;

    if (container) {
      if (direction === 'left') {
        container.scrollLeft -= scrollAmount;
      } else if (direction === 'right') {
        container.scrollLeft += scrollAmount;
      }
    }
  };

  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };


  useState(() => {
    // const getCurrentLocationPublicIp = async () => {
    //   const response = await fetch('https://ipapi.co/json/');
    //   const data = await response.json();
    //   setLongitude(data.longitude);
    //   setLatitude(data.latitude);
    // }
    const getCurrentLocationPublicIp = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setLongitude(data.longitude);
        setLatitude(data.latitude);
      } catch (error) {
        console.error('Error fetching location:', error);
        // Set default values on error
        setLongitude(35.5833);
        setLatitude(33.95);
      }
    };

    getCurrentLocationPublicIp();

  },[]);


  const resetAllPropertiesDefault = async () => {
    try {
      

      // setLocation();

      // setFi(allPropertiesDefault);
      setSelectedCategory(0,[
        onSearch({
            location,
            checkIn,
            checkOut,
            guests,
            minPrice,
            maxPrice,
            maxRange,
            minRange,
            selectedCategory,
            selectedPropertyType,
            selectedBedroom,
            selectedBeds,
            selectedBathroom,
            long,
            lat,
            selectedButton,
          })
      ]);
      // const allPropertiesDefault = await Config.getHomeCardDetails(long,lat);
      


      

    } catch (exception){
      console.error("error : ",exception);
    }
  }

  //on clicking search
  const handleSearchClick = async () => {
    try {
      
      if(long !== 0 && lat !== 0){
        
        
        
        const searchResults = await Config.getHomeCardDetails(
          long,
          lat,
          selectedButton,checkIn,checkOut,
          minPrice,maxPrice,minRange,maxRange,
          selectedCategory,selectedPropertyType,
          selectedBedroom,selectedBeds,selectedBathroom,
          location

        );
  
        // console.log("search results",searchResults);
          
  
  
        onSearch({
          location,
          checkIn,
          checkOut,
          guests,
          minPrice,
          maxPrice,
          maxRange,
          minRange,
          selectedCategory,
          selectedPropertyType,
          selectedBedroom,
          selectedBeds,
          selectedBathroom,
          long,
          lat,
          selectedButton,
        });


      } else {

        // console.log("long or lat is 0");

      }


      
    } catch (error) {
      console.error('Error during search:', error);
    }
  };

  const convertDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${year}/${month < 10 ? `0${month}` : month}/${day < 10 ? `0${day}` : day
      }`;
  };
  const convertDateR = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day
      }`;
  };

  const handlePriceChange = ({ min, max }) => {
    setMinPrice(min);
    setMaxPrice(max);
  };
  const handleRangeChange = ({ min, max }) => {
    setMinRange(min);
    setMaxRange(max);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsFilterOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsFilterOpen(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleGuestsChange = (event) => {
    let inputValue = event.target.value;

    if (inputValue === "") {
      setGuests("");
    } else {
      const parsedValue = parseInt(inputValue, 10);
      if (!isNaN(parsedValue) && parsedValue >= 0) {
        setGuests(parsedValue.toString());
      }
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/categories`
        );
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleCategoryClick = (category) => {


    if(category.id !== selectedCategory){
      setSelectedCategory(category.id);
      // console.log('Selected Category ID:', category.id);
    } else {
      setSelectedCategory(0);
    }
    
  };

  return (
    <div className="search-bar">
      <Grid container spacing={2} alignItems="center" justifyContent="center">

    


      <Grid item xs={12} sm={3} md={1.8}>
      {/* <Grid item > */}
      <Paper style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '10px', }} >
        <ButtonGroup 
          variant="contained"
          aria-label="outlined primary button group"
          className={classes.buttonGroup}
          orientation={isSmallScreen ? 'vertical' : 'horizontal'}
          
         
        >
          <Button
            className={classes.button}
            style={{
              background: selectedButton === 'all' ? '#bb0129' : undefined,
              
            }}
            onClick={() => handleButtonClick('all')}
          >
            <label
              className={classes.label}
              style={{
                
                color: selectedButton === 'all' ? 'white' : 'black',
                fontWeight: selectedButton === 'all' ? 'bold' : 'normal',
            

              }}
            >
              All
            </label>
          </Button>
          <Button
            className={classes.button}
            style={{
              background: selectedButton === 'rent' ? '#bb0129' : undefined,
             

            }}
            onClick={() => handleButtonClick('rent')}
          >
            <label
              className={classes.label}
              style={{
                color: selectedButton === 'rent' ? 'white' : 'black',
                fontWeight: selectedButton === 'rent' ? 'bold' : 'normal',
               

              }}
            >
              Rent
            </label>
          </Button>
          <Button
            className={classes.button}
            style={{
              background: selectedButton === 'buy' ? '#bb0129' : undefined,
              fontFamily: 'MMC Regular', // Apply font to Button
             

            }}
            onClick={() => handleButtonClick('buy')}
          >
            <label
              className={classes.label}
              style={{
                color: selectedButton === 'buy' ? 'white' : 'black',
                

              }}
            >
              Buy
            </label>
          </Button>
        </ButtonGroup>
      </Paper>
    </Grid>


        {/* <Grid item xs={12} sm={3} md={2}> */}
        <Grid item >
          <GoogleMapsAutocomplete location={location} setLocation={setLocation} value={location} />
        </Grid>

        {/* <Grid item xs={12} sm={3} md={2}> */}
        <Grid item >


        <RangePicker
          style={{ width: '100%', height: '56px', borderRadius: '15px',textAlign: 'center', }}
          separator=""
          // separator=" - "
          disabledDate={disabledDate}
          placeholder={['   Start Date   ', '    End Date   ']} // Custom placeholders

          onCalendarChange={(dates) => {
            // console.log(dates);
            if (dates && dates.length > 1) {
              // console.log(dates);
              
              setCheckIn(dates[0].format('YYYY-MM-DD'));
              if(dates[1]){
                setCheckOut(dates[1].format('YYYY-MM-DD'));
              }else{
                setCheckOut(null);
              }
            } else {
              setCheckIn(null);
              setCheckOut(null);
            }
          }}
        />
         
          
        </Grid>

       
        {/* <Grid item xs={12} sm={3} md={2} container justifyContent={isSmallScreen ? 'center' : 'start'} style={{ alignItems: 'center', display: 'flex' }}> */}
        <Grid item  >
          <IconButton aria-label="filter" onClick={handleClick}>
            <TuneIcon fontSize="inherit" style={{ color: "black" }} />
          </IconButton>
          
          
          
          <IconButton aria-label="search"
            id="basic-button"
            aria-controls={openMenu ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? 'true' : undefined}
            onClick={handleClickMenu}>
            <GridViewIcon fontSize="inherit" style={{ color: "black" }} />
          </IconButton>



          <IconButton aria-label="search" onClick={handleSearchClick}>
            <SearchIcon fontSize="inherit" style={{ color: "black" }} />
          </IconButton>


          


        </Grid>


      </Grid>





      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div
          id={id}
          open={open}
          onClose={handleClose}
          style={{
            width: '300px',
            height: '400px',
            padding: '16px',
          }}
        >
          <Filters
            isOpen={isFilterOpen}
            onApplyFilters={handlePriceChange}
            onPriceChange={handlePriceChange}
            onRangeChange={handleRangeChange}
            selectedPropertyType={selectedPropertyType}
            setSelectedPropertyType={setSelectedPropertyType}
            selectedBedroom={selectedBedroom}
            setSelectedBedroom={setSelectedBedroom}
            selectedBeds={selectedBeds}
            setSelectedBeds={setSelectedBeds}
            selectedBathroom={selectedBathroom}
            setSelectedBathroom={setSelectedBathroom}
          />
        </div>
      </Popover>

      <Menu
        id="basic-menu"
        anchorEl={anchorElMenu}
        open={openMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {categories.map((category) => (
          <MenuItem
            key={category.id}
            onClick={() => handleCategoryClick(category)}
            selected={selectedCategory === category.id}
            sx={{ backgroundColor: selectedCategory === category.id ? '#efefef !important' : 'inherit' }}
          >
            <div className={`category ${selectedCategory === category.id ? 'selected' : ''}`}>
              <img src={`${IMAGES_URL}${category.icon}`} alt={category.name} />
              <div className="category-name">{category.name}</div>
            </div>
          </MenuItem>
        ))}
      </Menu>

    </div>

  );
}

export default SearchBar;