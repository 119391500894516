import './App.css';
import Home from './Home';
import Property from './PropertyDetails/Property';
import Profile from './Components/Account/Profile/Profile'
import Billing from './Components/Account/Billing/Billing'
import Reservation from './Components/Reservation/Payment/Payment'
import BecomeOwner from './Components/Owner/BecomeOwner/BecomeOwner'
import Owner from './Components/Owner/Owner'
import AboutUs from './pages/aboutus/aboutus'
import FacilityManagement from './pages/facility/FacilityManagement';
import Crypto from './pages/crypto/crypto';
import ContactUs from './pages/contactus/contactus';
import { BrowserRouter , Route, Routes } from "react-router-dom";
import { useState } from 'react'; // For managing the language state
import { LanguageContext } from './LanguageContext';
import "./GoogleTranslate.css"
import React, { useEffect } from "react";
import { AvatarAssetsProvider } from './Components/AvatarAssetsContext';

function App() {

  const [language, setLanguage] = useState('en'); // Default language is English

  // Determine the styles dynamically
  const appStyles =
    language === 'ar'
      ? { direction: 'rtl', textAlign: 'left' }
      : { direction: 'ltr', textAlign: 'left' };


     
      
      
      

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      <div id="google_translate_element"></div>
      <div className="App" style={appStyles} >
        
        <BrowserRouter>
          <AvatarAssetsProvider>

            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/propertydetails" element={<Property />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/billing" element={<Billing />} />
              <Route path="/reservation" element={<Reservation />} />
              <Route path="/become-an-owner" element={<BecomeOwner />} />
              <Route path="/owner" element={<Owner />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/FacilityManagement" element={<FacilityManagement />} />
              <Route path="/crypto" element={<Crypto />} />
              <Route path="/contact" element={<ContactUs />} />

            </Routes>
          </AvatarAssetsProvider>
        </BrowserRouter>  

      </div>
    </LanguageContext.Provider>

  );
}

export default App;




/*

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>Open modal</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}


*/