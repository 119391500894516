import React, { useState, useEffect } from "react";
import Config from "../../../Services/Config";
import Navbar from "../../../NavBar/NavBar";
import { useLocation, useNavigate } from 'react-router-dom';
import SnackbarComponent from "../../../SnackBar/SnackBar";
import { Card, CardContent, Typography, Box, Grid, Button, Select, MenuItem, FormControl, CardMedia, Divider } from '@mui/material';
import { useAuth } from "../../../AuthContext";
import { IMAGES_URL } from "../../../Services/Config";
import { useLanguage } from "../../../LanguageContext";

function ReservationPayment() {
  const location = useLocation();
  const [reservationDetails, setReservationDetails] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedCard, setSelectedCard] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { isAuthenticated } = useAuth();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const { language, setLanguage } = useLanguage();
  const navigate = useNavigate();

  const reservationData = {
    reservation_start: location.state?.checkIn || null,
    reservation_end: location.state?.checkOut || null,
    property_id: location.state?.propertyId
  };

  const handleCardChange = (event) => {
    setSelectedCard(event.target.value);
  };

  useEffect(() => {
    const fetchReservationDetails = async () => {
      try {
        setIsLoading(true);
        const result = await Config.makeReceipt(reservationData);
        setReservationDetails(result);
      } catch (error) {
        setError("Failed to fetch reservation details");
      } finally {
        setIsLoading(false);
      }
    };
    

    const fetchPaymentMethods = async () => {
      try {
        const user = await Config.getUserDetails();
        if (user.data.id) {
          const id = user.data.id;
          const data = await Config.getPaymentMethods(id);
          console.log("dataa",data);
          
          setPaymentMethods(data);
          if (data.length > 0) {
            setSelectedCard(data[0].id);
          }
        }
      } catch (error) {
        setError("Failed to fetch payment methods");
      }
    };

    fetchPaymentMethods();
    fetchReservationDetails();
  }, [location.state]);

  const handleConfirmAndPay = async () => {
    const reservationPaymentData = {
      ...reservationData,
      payment_id: selectedCard,
    };

    try {
      setIsLoading(true);
      const result = await Config.makeReservation(reservationPaymentData);

      if (result.success) {
        setSnackbarMessage(result.message);
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } else {
        setSnackbarMessage(result.message);
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      }
    } catch (error) {
      setError("Failed to process payment");
    } finally {
      setIsLoading(false);
    }
  };

  const formatExpiryDate = (expiryDate) => {
    const date = new Date(expiryDate);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${year}`;
  };

  return (
    <>
      {isLoading ? (
        <div className="loading-overlay">
          <div className="loader-icon">Loading...</div>
        </div>
      ) : (
        <div>
          <Navbar handleChangeLanguage={setLanguage} />
          <div className="myContainer">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h3" align="center">Confirm and Pay</Typography>
                {reservationDetails && (
                  <Card sx={{ maxWidth: 600, mx: "auto", mt: 5 }}>
                    <CardMedia
                      component="img"
                      height="300"
                      image={`${IMAGES_URL}${reservationDetails.property.first_image}`}
                      alt={reservationDetails.property.name}
                    />
                    <CardContent>
                      <Typography variant="h4">Your Stay</Typography>
                      {reservationDetails.property.rent_buy === 0 && (
                        <>
                          <Typography variant="h5">Reserved Dates</Typography>
                          <Typography>
                            Start: {new Date(reservationData.reservation_start).toLocaleDateString()}<br />
                            End: {new Date(reservationData.reservation_end).toLocaleDateString()}
                          </Typography>
                        </>
                      )}
                      <Typography variant="h5">Guests</Typography>
                      <Divider sx={{ my: 2 }} />
                      <Typography variant="h4">{reservationDetails.property.name}</Typography>
                      <Typography>Location: {reservationDetails.property.location}</Typography>
                      <Box mt={2}>
                        <Grid container spacing={1}>
                          <Grid item xs={10}>
                            <Typography>Price per day:</Typography>
                            <Typography>Days:</Typography>
                            <Typography>Subtotal:</Typography>
                            <Typography>Fees:</Typography>
                            <Typography>System Fee:</Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography>${reservationDetails.dailyPrice}</Typography>
                            <Typography>x {reservationDetails.days}</Typography>
                            <Typography>${reservationDetails.price}</Typography>
                            <Typography>${reservationDetails.fees}</Typography>
                            <Typography>${reservationDetails.systemFee}</Typography>
                          </Grid>
                        </Grid>
                        <Divider sx={{ my: 2 }} />
                        <Grid container spacing={1}>
                          <Grid item xs={10}>
                            <Typography sx={{ fontWeight: 'bold' }}>Total:</Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography sx={{ fontWeight: 'bold' }}>${reservationDetails.total}</Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </CardContent>
                  </Card>
                )}
                <Card sx={{ maxWidth: 600, mx: "auto", mt: 5 }}>
                  <CardContent>
                    <Typography>Select a Payment Method</Typography>
                    <FormControl fullWidth>
                      <Select
                        value={selectedCard}
                        onChange={handleCardChange}
                      >
                        {paymentMethods.length > 0 ? (
                          paymentMethods.map((method) => (
                            <MenuItem key={method.id} value={method.id}>
                              **** {method.last_four_digits} - Expires {formatExpiryDate(method.expiry_date)}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>No payment methods available</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    <Button variant="contained" sx={{ mt: 3 }} onClick={() => navigate('/billing')}>
                      Add New Payment Method
                    </Button>
                  </CardContent>
                </Card>
                <Box sx={{ textAlign: 'end', mt: 3 }}>
                  <Button variant="contained" onClick={handleConfirmAndPay} disabled={!selectedCard}>
                    Confirm and Pay
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </div>
          <SnackbarComponent
            open={openSnackbar}
            setOpen={setOpenSnackbar}
            message={snackbarMessage}
            severity={snackbarSeverity}
          />
        </div>
      )}
    </>
  );
}

export default ReservationPayment;
