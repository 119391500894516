import {
  CameraControls,
  ContactShadows,
  Environment,
  Text,
} from "@react-three/drei";
import {  useGLTF } from "@react-three/drei";

import { Suspense, useEffect, useRef, useState } from "react";
import { Avatar } from "./Avatar";
// import { AvatarMob } from "./AvatarMob";
import { useAvatarAssets } from "./AvatarAssetsContext"; // Use the hook to access the context

import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import JSZip from 'jszip';
// import { AvatarAssetsProvider } from "./AvatarAssetsContext";
export const Experience = ({showAvatar,greet,latestMsg,taganimation}) => {
  const cameraControls = useRef();
  const [enableControlls,setEnableControlls] = useState(true);
  const [isAvatarLoaded, setIsAvatarLoaded] = useState(false);
  const timestamp = new Date().getMinutes() ;
  const { assetsLoaded } = useAvatarAssets(); // Get the loading status from context



  
  


  useEffect(() => {
    // cameraControls.current.setLookAt(0, 2, 5, 0, 1.5, 0);
    cameraControls.current.setLookAt(0, 2, 5, 0, 1.5, 0);
    // cameraControls.current.setLookAt(0, 0, 0, 0, 0, 0);
    // cameraControls.current.setLookAt(0,0,0,)

    if (isAvatarLoaded) {
      
      // Disable controls 1 seconds after the avatar is loaded
      const timeout = setTimeout(() => {
        setEnableControlls(false);
      }, 1000);

      // Cleanup timeout on unmount
      return () => clearTimeout(timeout);
    }

   

  }, [isAvatarLoaded]);

  



  return (
    <>
      <CameraControls ref={cameraControls} enabled={enableControlls}/>
      <Environment preset="sunset" />
     
      {/* <AvatarAssetsProvider> */}

        {showAvatar && (
          <Avatar showAvatar={showAvatar} greet={greet} latestMsg={latestMsg} taganimation={taganimation}  cameraControls={cameraControls} onLoad={() => setIsAvatarLoaded(true)}  />
        )}

      {/* </AvatarAssetsProvider> */}



      <ContactShadows opacity={0.7} />
    </>
  );
};
