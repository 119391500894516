import axios from "axios";
// import {CardElement,useStripe,useElements} from '@stripe/react-stripe-js'
// const API_URL = "http://localhost:8000/api";
const IMAGES_URL = "https://divinests.com";
const IMAGES_URL2 = "https://divinests.com";
// const IMAGES_URL = "http://localhost:8000";
// const IMAGES_URL2 = "http://localhost:8000";
// const PYTHON_URL = "https://divinests.com/pythonchat/";
// const PYTHON_URL = "http://localhost:5000/";
const PYTHON_URL = "./chat/";
// const PYTHON_URL = "https://divinests.com/pythonchat/";

const API_URL = "https://divinests.com/admincms/api";
// const IMAGES_URL = "http://divinests.com";
//const IMAGES_URL = "http://192.168.0.109:8000";
//  const API_URL = "http://74.208.98.3/admincms/api";
//  const IMAGES_URL = "http://74.208.98.3";
//const IMAGES_URL_edit = "http://74.208.98.3/admincms/";
const TOKEN_KEY = "token";




class Config {

  static async resetPass(email) {
    const token = localStorage.getItem(TOKEN_KEY);

    const body = {
      "email": email
    }
    try {
      const response = await axios.post(`${API_URL}/user/reset`, body, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.error("Error validating token:", error);
      throw error;
    }
  }

  static async getPropertyDetails(propertyId) {
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.get(`${API_URL}/property/${propertyId}`, config);
      
      return response.data;
    } catch (error) {
      console.error('Error validating token: ', error);
      throw error;
    }
  }

  static async getFees() {
    
    try {
      const response = await axios.get(`${API_URL}/fees`);
      
      return response.data;
    } catch (error) {
      console.error('Error validating token: ', error);
      throw error;
    }
  }

  static async getHomeCardDetails(long,lat,rent_buy, checkIn, checkOut, minPrice, maxPrice, minRange, maxRange, selectedCategory, selectedPropertyType, selectedBedroom, selectedBeds, selectedBathroom, location) {
   
    const token = "token";
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    
    // Determine the value for rent_buy parameter
    let rentBuyValue;
    if (rent_buy === 'rent') {
        rentBuyValue = 0;
    } else if (rent_buy === 'buy') {
        rentBuyValue = 1;
    } else {
        rentBuyValue = ''; // or any other default value if needed
    }

    try {
        // let formattedCheckIn = "";
        // let formattedCheckOut = "";
        
        // if(checkIn == null){
        //   checkIn = "";
        //   checkOut = "";
        // }
        // console.log("checkIn " , checkIn);
        // console.log("checkOut " ,checkOut);
        // if(checkIn){
        //   formattedCheckIn = checkIn.format('YYYY-MM-DD');
        // }
        // if(checkOut){
        //   formattedCheckOut = checkOut.format('YYYY-MM-DD');
        // }
        // console.log("formattedCheckIn " , formattedCheckIn);
        // console.log("formattedCheckOut " ,formattedCheckOut);
        
      
        
        const response = await axios.get(`${API_URL}/properties`, {

            params: {
                lat: lat,
                lon: long,
                category_id: selectedCategory,
                type_id: selectedPropertyType,
                // dedicated_bathroom: 1,
                // private_bathroom: 1,
                // shared_bathroom: 1,
                num_beds: selectedBeds,
                num_bedrooms: selectedBedroom,
                num_bathrooms: selectedBathroom,
                min_price: minPrice,
                max_price: maxPrice,
                min_range: minRange,
                max_range: maxRange,
                available_at: checkIn,
                availability_ends_at: checkOut,
                rent_buy: rentBuyValue,
                location: location,
                limit: 10,
                offset: 0,
            },
            headers: config.headers,
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching home card details: ', error);
        throw error;
    }
}

static async getHomeCardDetailsAi(ids) {
   
  const token = "token";
  const config = {
      headers: { Authorization: `Bearer ${token}` },
  };
  
  
  try {
      
      
    
      
      const response = await axios.get(`${API_URL}/propertiesAi`, {

          params: {
              ids: ids,
              limit: 10,
              offset: 0,
          },
          headers: config.headers,
      });
      return response.data;
  } catch (error) {
      console.error('Error fetching home card details: ', error);
      throw error;
  }
}



static async searchFunctionality(rentBuy, lon, lat) {
  const token = localStorage.getItem(TOKEN_KEY);

  const config = {
      headers: { Authorization: `Bearer ${token}` },
  };

  try {
      // Determine the rent_buy parameter value
      let rentBuyParam = "";
      if (rentBuy === "rent") {
          rentBuyParam = "rent_buy=0";
      } else if (rentBuy === "buy") {
          rentBuyParam = "rent_buy=1";
      }

      // Construct the query string
      const queryString = new URLSearchParams({
          lon: lon,
          lat: lat,
          ...(rentBuyParam ? { rent_buy: rentBuyParam.split('=')[1] } : {}), // Extract value from `rentBuyParam`
      }).toString();

      // Make the request
      const response = await axios.get(`${API_URL}/properties?${queryString}`, config);
      return response.data;
  } catch (error) {
      console.error('Error validating token: ', error);
      throw error;
  }
}

  static async categoryFunctionality(category_id) {
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.get(`${API_URL}/categories/getProperties?category_id=${category_id}`, config);
      return response.data;
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  }

  static async makeReceipt(body) {
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.post(`${API_URL}/receipt`, body, config);
      return response.data;
    } catch (error) {
      console.error('Error validating token: ', error);
      throw error;
    }
  }

  static async makeReservation(body) {
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.post(`${API_URL}/user/reserve`, body, config);
      return response.data;
    } catch (error) {
      console.error('Error validating token: ', error);
      throw error;
    }
  }


  static async acceptReservation(body){
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.post(`${API_URL}/owner/accept-reservation`, body, config);
      // console.log("response reserve : ",response);
      return response.data;
    } catch (error) {
      console.error('Error validating token: ', error);
      throw error;
    }
  }


  static async rejectReservation(body){
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.post(`${API_URL}/owner/reject-reservation`, body, config);
      // console.log("response reserve : ",response);
      return response.data;
    } catch (error) {
      console.error('Error validating token: ', error);
      throw error;
    }
  }


  static async getUserDetails() {
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.get(`${API_URL}/token/validate`, config);
      return response.data;
    } catch (error) {
      console.error('Error validating token:', error);
      throw error;
    }
  }

  static async updateUserDetails(name, lastname, email, phoneNumber) {
    const token = localStorage.getItem(TOKEN_KEY);
    // console.log(token)

    const body = {
      "name": name,
      "last_name": lastname,
      "email": email,
      "phone_number": phoneNumber
    }
    // console.log("body: ",body);
    
    try {
      const response = await axios.post(`${API_URL}/user/update`, body, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.error("Error validating token:", error);
      throw error;
    }
  }

  static async getallchats(pages) {
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.get(`${API_URL}/user/chat/getall?page=${pages}`, config);
      return response.data.data;
    } catch (error) {
      console.error('Error fetching chats:', error.response ? error.response.data : error.message);
      throw error;
    }
  }
  

  static async getallmessages(user_id) {
    const token = localStorage.getItem(TOKEN_KEY);
    if(!token){
      return;
    }
    
    
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.get(`${API_URL}/user/chat/messages?user_id=${user_id}`, config);

      // Assuming "created_at" is a property in the API response, include it in the returned data
      const messagesWithData = response.data.data.map(message => ({
        ...message,
        created_at: message.created_at, // Adjust this based on the actual structure of the API response
      }));

      return messagesWithData;
    } catch (error) {
      console.error('Error validating token:', error);
      throw error;
    }
  }
  static async updateUnreadMessaged(user_id){
    const token = localStorage.getItem(TOKEN_KEY);

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const body = {
      "user_id": user_id,
    
    }
    try {
      const response = await axios.post(`${API_URL}/resetUnreadMessages`,body, config);
      
      // Assuming "created_at" is a property in the API response, include it in the returned data
      // const messagesWithData = response.data.data.map(message => ({
      //   ...message,
      //   created_at: message.created_at, // Adjust this based on the actual structure of the API response
      // }));

      // return messagesWithData;
    } catch (error) {
      console.error('Error validating token:', error);
      throw error;
    }

  }


  static async send_msg(message, user_id) {
    const token = localStorage.getItem(TOKEN_KEY);

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const body = {
        "user_id": user_id,
        "body": message
      }
      const response = await axios.post(`${API_URL}/message`, body, config);
      const chatArray = [
        {
          id: 300,
          chat_id: "7",
          user_id: "17",
          body: "hello sir?!!!",
          created_at: "2024-10-16T11:57:10.000000Z",
          updated_at: "2024-10-16T11:57:10.000000Z",
          deleted_at: null
        },
        // You can add more objects here if needed
      ];
      // return chatArray[0];
      
      return response.data.data;
    } catch (error) {
      console.error('Error validating token:', error);
      throw error;
    }

  }

  static async updateUserImage(image) {
    const token = localStorage.getItem(TOKEN_KEY);

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    try {
      const response = await axios.post(`${API_URL}/user/uploadImage`, image, config);
      return response.data;
    } catch (error) {
      console.error('Error validating token:', error);
      throw error;
    }
  }

  static async updateUserPassword(oldPass, newPass) {
    const token = localStorage.getItem(TOKEN_KEY);

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const body = {
      "old_password": oldPass,
      "password": newPass
    }
    try {
      const response = await axios.post(`${API_URL}/user/update-password`, body, config);
      return response.data;
    } catch (error) {
      console.log('Error validating token:', error);
      throw error;
    }
  }

  static async getUserAddress() {
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.get(`${API_URL}/user/address`, config);
      return response.data;
    } catch (error) {
      console.error('Error validating token:', error);
      throw error;
    }
  }

  static async updateAddressDetails(country, state, city, street, zip) {
    const token = localStorage.getItem(TOKEN_KEY);
    // console.log(token)

    const body = {
      "country": country,
      "state": state,
      "city": city,
      "street": street,
      "zip": zip,
    }
    try {
      const response = await axios.post(`${API_URL}/user/address`, body, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.error("Error validating token:", error);
      throw error;
    }
  }
// Add this method to your Config class
static calculateExpirationDate(property) {
  const rentPeriodMonths = property.monthly_rent; // Number of months valid
  const createdDate = new Date(property.created_at || property.updated_at);
  
  const expirationDate = new Date(createdDate.setMonth(createdDate.getMonth() + rentPeriodMonths));
  return expirationDate;
}

  static async getOwnerProperties() {
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.get(`${API_URL}/owner/properties`, config);
      // console.log(response);
      return response;
    } catch (error) {
      console.error('Error validating token:', error);
      throw error;
    }
  }
  static async getSponsorPrice() {
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.get(`${API_URL}/owner/sponsorsprice`, config);
      // console.log(response);
      return response;
    } catch (error) {
      console.error('Error validating token:', error);
      throw error;
    }
  }
  static async SubscriptionPrice() {
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.get(`${API_URL}/owner/subscriptionprice`, config);
      // console.log(response);
      return response;
    } catch (error) {
      console.error('Error validating token:', error);
      throw error;
    }
  }

  static async getOwnerSponserId(propertyId) {
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.get(`${API_URL}/owner/sponsors/${propertyId}`, config);
      // console.log(response);
      return response;
    } catch (error) {
      console.error('Error validating token:', error);
      throw error;
    }
  }

  static async getOwnerRequests() {
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.get(`${API_URL}/owner/requests`, config);
      return response;
    } catch (error) {
      console.error('Error validating token:', error);
      throw error;
    }
  }

  static async getOwnerPropertyRequest(body){
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.post(`${API_URL}/owner/requestsProperty`,body,config);
      return response;
    } catch (error) {
      console.error('Error validating token:', error);
      throw error;
    }
  }


  static async getOwnerAllRequestsThisProperty(body){
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.post(`${API_URL}/owner/requestsProperty`,body,config);
      return response;
    } catch (error) {
      console.error('Error validating token:', error);
      throw error;
    }
  }


  static async getRequestsPropertyFilter(body){

    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.post(`${API_URL}/owner/filterRequestsProperty`,body,config);
      return response;

    } catch (error){
      console.error('Error validation tokem: ',error);
      throw error;
    }

  }

  static async getOwnerPropertiesFilter(propertyFilter){
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const body = {
        "propertyName" : propertyFilter,
      }

      const response = await axios.post(`${API_URL}/owner/filterProperties`,body,config);
      return response;

    } catch (error){
      console.error('Error validation tokem: ',error);
      throw error;
    }
  }


  
  static async getOwnerPropertiesPagination(pagination,direction) {
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const body = {
        "pagination": pagination,
        "direction": direction==="left" ? "left" : "right",
      }
      
      const response = await axios.post(`${API_URL}/owner/propertiesPagination`, body, config);
      // console.log("response pagination : ",response.data);
      return response;
    } catch (error) {
      console.error('Error validating token:', error);
      throw error;
    }
  }
// In your Config.js or similar service file

  static async getTransactionHistory() {
    try {
      const token = localStorage.getItem(TOKEN_KEY);
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await axios.get(`${API_URL}/user/reservation-history`, config);
      return response.data;
    } catch (error) {
      console.error('Error fetching transaction history:', error);
      throw error;
    }
  }
  static async  getAdsByUser(userId) {
    const response = await fetch(`${API_URL}/ads/user/${userId}`); // Adjust the endpoint as needed
    if (!response.ok) {
      throw new Error('Failed to fetch ads');
    }
    return response.json();
  }
  
  static async getSubscriptionsByUser(userId) {
    return axios.get(`${API_URL}/subscriptions/user/${userId}`)
      .then(response => response.data)
      .catch(error => {
        console.error('Error fetching subscriptions by user ID:', error);
        throw error;
      });
  }

  // Create a new subscription
  static async createSubscription(subscriptionData) {
    return axios.post(`${API_URL}/subscriptions`, subscriptionData)
      .then(response => response.data)
      .catch(error => {
        console.error('Error creating subscription:', error);
        throw error;
      });
  }

  // Update a subscription
  static async updateSubscription(subscriptionId, subscriptionData) {
    return axios.put(`${API_URL}/subscriptions/${subscriptionId}`, subscriptionData)
      .then(response => response.data)
      .catch(error => {
        console.error('Error updating subscription:', error);
        throw error;
      });
  }
  // static async  createPriority (propertyId, userId, price, monthlyRent){
  //   // try {
  //   //   const response = await axios.post(`${API_URL}/priority`, {
  //   //     property_id: propertyId,
  //   //     user_id: userId,
  //   //     price: price,
  //   //     monthly_rent: monthlyRent,
  //   //   });
  
  //   //   if (response.data.success) {
  //   //     console.log('Priority created successfully:', response.data.data);
  //   //     // Perform any additional actions here, like updating state or UI
  //   //   } else {
  //   //     console.error('Failed to create priority:', response.data.message);
  //   //   }
  //   // } catch (error) {
  //   //   console.error('Error creating priority:', error.response?.data || error.message);
  //   // }
  // };

  // Delete a subscription
  static async  deleteSubscription(subscriptionId) {
    return axios.delete(`${API_URL}/subscriptions/${subscriptionId}`)
      .then(response => response.data)
      .catch(error => {
        console.error('Error deleting subscription:', error);
        throw error;
      });
  }
  static async getPaymentMethods(user_id) {
    try {
      // Retrieve the token from local storage
      const token = localStorage.getItem(TOKEN_KEY);
  
      // Configure the headers for the request
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
  
      // Make the GET request to the API
      const response = await axios.get(`${API_URL}/payment-methods/user/${user_id}`, config);
  
      // Return the data from the response
      return response.data;
    } catch (error) {
      // Log the error for debugging
      console.error('Error fetching payment methods:', error);
  
      // Handle specific status codes
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('Response error:', error.response.data);
        console.error('Status code:', error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('Request error:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error message:', error.message);
      }
  
      // Throw the error to be handled by the caller
      throw error;
    }
  }
  


  static async addCard(data) {
    try {

      const token = localStorage.getItem(TOKEN_KEY);
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const user = await Config.getUserDetails();
      if (user.data.id) {
        const id = user.data.id;
      
      // Prepare the request body with cardholder's name
      const body = {
        user_id: id, // Ensure this is provided in `data` or dynamically set as needed
        payment_id: data.payment_id, // payment_id in stripe
        last4: data.number, // last 4 digits
        exp_month: data.exp_month,
        exp_year: data.exp_year,
        name: data.name, // Include the cardholder's name
      };

     
    
  
      // Send the POST request to the API endpoint
      const response = await axios.post(`${API_URL}/payment-methods`, body, config);
  
      return response.data;
    }
    } catch (error) {
      console.log("Error Exception", error);
      throw error;
    }
  }
  
  

  static async removeCard(cardId) {
    const token = localStorage.getItem(TOKEN_KEY);
    // console.log(token)
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let body = {
      "card_id": cardId
    }
    try {
      const response = await axios.post(`${API_URL}/user/remove-card`, body, config);
      return response.data;
    } catch (error) {
      console.error('Error validating token: ', error);
      throw error;
    }
  }

  static async addProperty(formData) {
    const token = localStorage.getItem(TOKEN_KEY);

    if (!token) {
      console.error('No authentication token found.');
      throw new Error('Authentication token missing.');
    }

    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        // Do not set 'Content-Type' for FormData; let axios handle it
      },
    };

   
    // Show notification
    // alert('Form data has been logged!');
    
    try {
      
      // No need to serialize formData if it's an instance of FormData
      const response = await axios.post(`${API_URL}/owner/add-property`, formData, config);
      
      return response.data;
      
    } catch (error) {
      // Check if error.response exists to get detailed error information
      if (error.response) {
        if (error.response.status === 422) {
          console.error('Validation errors:', error.response.data.errors);
        } else if (error.response.status === 401) {
          console.error('Unauthorized request. Please check your authentication token.');
        } else {
          console.error(`API error: ${error.response.status} - ${error.response.statusText}`);
        }
      } else {
        console.error('Network or server error:', error.message);
      }

      throw error; // Re-throw error for further handling if needed
    }
  }

  static async sponsorProperty(formData) {
    const token = localStorage.getItem(TOKEN_KEY);

    if (!token) {
      console.error('No authentication token found.');
      throw new Error('Authentication token missing.');
    }
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.post(`${API_URL}/owner/sponsor-property`, formData, config);
      return response.data;
    } catch (error) {
      console.error('Error adding property: ', error);
      throw error;
    }
  }
  static async subsctionOwner(formData) {
    const token = localStorage.getItem(TOKEN_KEY);

    if (!token) {
      console.error('No authentication token found.');
      throw new Error('Authentication token missing.');
    }
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.post(`${API_URL}/owner/subsction`, formData, config);
      return response.data;
    } catch (error) {
      console.error('Error adding property: ', error);
      throw error;
    }
  }

  static async editProperty(formData) {
    const token = localStorage.getItem(TOKEN_KEY);

    if (!token) {
      console.error('No authentication token found.');
      throw new Error('Authentication token missing.');
    }
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      console.log("formData update property",formData);
      
      const response = await axios.post(`${API_URL}/owner/update-property`, formData, config);
      return response.data;
    } catch (error) {
      console.error('Error adding property: ', error);
      throw error;
    }
  }

  static async uploadImgs(formData) {
    const token = localStorage.getItem(TOKEN_KEY);

    if (!token) {
      console.error('No authentication token found.');
      throw new Error('Authentication token missing.');
    }

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    try {
      // console.log("form data image",formData);

   
      const response = await axios.post(`${API_URL}/owner/uploadImage`, formData, config);
      // const response = await axios.post(`${API_URL}/owner/uploadImageEdit`, formData, config);
      // console.log("response api : ",response.data);
      return response.data;
    } catch (error) {
      console.error('Error adding property: ', error);
      throw error;
    }
  }
  static async uploadVRContent(formData) {
    const token = localStorage.getItem(TOKEN_KEY);

    if (!token) {
        console.error('No authentication token found.');
        throw new Error('Authentication token missing.');
    }

    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data' // Ensure correct content type
        }
    };

    try {
        // Log the form data for debugging
        // console.log('Uploading VR Content: ',formData);
        
        // for (let pair of formData.entries()) {
        //     console.log(`${pair[0]}: ${pair[1].name}`);
        // }

        const response = await axios.post(`${API_URL}/owner/uploadVRContent`, formData, config);
        return response.data;
    } catch (error) {
        console.error('Error uploading VR content:', error.response ? error.response.data : error.message);
        throw error;
    }
}

static async upload360Video(formData) {
    const token = localStorage.getItem(TOKEN_KEY);

    if (!token) {
        console.error('No authentication token found.');
        throw new Error('Authentication token missing.');
    }

    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data' // Ensure correct content type
        }
    };

    try {
      

        const response = await axios.post(`${API_URL}/owner/upload360Video`, formData, config);
        return response.data;
    } catch (error) {
        console.error('Error uploading 360-degree video:', error.response ? error.response.data : error.message);
        throw error;
    }
}
// Function to update VR URL for a property
static async updatePropertyVR(data) {
  const token = localStorage.getItem(TOKEN_KEY); // Replace 'TOKEN_KEY' with your actual token key

  if (!token) {
      console.error('No authentication token found.');
      throw new Error('Authentication token missing.');
  }

  const config = {
      headers: { Authorization: `Bearer ${token}` }
  };

  try {
      const response = await axios.post(`${API_URL}/properties/update-vr`, data, config);
      return response.data;
  } catch (error) {
      console.error('Error updating VR URL:', error.response ? error.response.data : error.message);
      throw error;
  }
}

static async updateProperty360(data) {
  const token = localStorage.getItem(TOKEN_KEY); // Replace 'TOKEN_KEY' with your actual token key

  if (!token) {
      console.error('No authentication token found.');
      throw new Error('Authentication token missing.');
  }

  const config = {
      headers: { Authorization: `Bearer ${token}` }
  };

  try {
      const response = await axios.post(`${API_URL}/properties/update-360`, data, config);
      return response.data;
  } catch (error) {
      console.error('Error updating 360 URL:', error.response ? error.response.data : error.message);
      throw error;
  }
}


static async uploadImgs(formData) {
  const token = localStorage.getItem(TOKEN_KEY);

  if (!token) {
    console.error('No authentication token found.');
    throw new Error('Authentication token missing.');
  }

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  try {
   

    const response = await axios.post(`${API_URL}/owner/uploadImage`, formData, config);
    
    return response.data;
  } catch (error) {
    console.error('Error adding property: ', error);
    throw error;
  }
}

  static async uploadImgsEdit(formEdit){
    const token = localStorage.getItem(TOKEN_KEY);

    if (!token) {
      console.error('No authentication token found.');
      throw new Error('Authentication token missing.');
    }

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    try {
      const response = await axios.post(`${API_URL}/owner/uploadImageEdit`, formEdit, config);
      // console.log(response.data);
      return response.data;
      
    } catch (error) {
      console.error('Error adding property: ', error);
      throw error;
    }

  }

  static async getCategoriesDetails() {
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.get(`${API_URL}/categories`, config);
      return response.data;
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  }

  static async getTypesDetails() {
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.get(`${API_URL}/getType`, config);
      return response.data;

    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  }

  static async getAmenities() {
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.get(`${API_URL}/amenitiesCategory`, config);
      return response.data;
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  }

  static async getAmenitiesDetails() {
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.get(`${API_URL}/amenitiesCategory`, config);
      return response.data;
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  }

  static async requestOwnership(formData) {
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    // const body = {
    //   'file' : formData.get('file'),
    //   'reason' : formData.get('reason'),
    // };



    try {
      // console.log("body",body);
      const response = await axios.post(`${API_URL}/user/request-ownership`,formData, config);
      // console.log("response api",response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  }

  static async deleteProperty(property_id) {
    const token = localStorage.getItem(TOKEN_KEY);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await axios.post(`${API_URL}/property/remove`, { property_id }, config);
      return response.data;
    } catch (error) {
      console.error('Error fetching data: ', error);
      throw error;
    }
  }
}

export { API_URL, IMAGES_URL, IMAGES_URL2, PYTHON_URL };
export default Config;