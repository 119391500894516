/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef, createContext, useContext, useDebugValue } from "react";
import "./NavBar.css";
import Button from "@mui/material/Button";
import { SvgIcon } from '@mui/material';

import Typography from "@mui/material/Typography";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonIcon from "@mui/icons-material/Person";
import IconButton from "@mui/material/IconButton";
import { useAuth } from "../AuthContext";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from '@mui/icons-material/Login';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import LanguageIcon from '@mui/icons-material/Language';
import PhoneIcon from '@mui/icons-material/Phone';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { FaBitcoin } from 'react-icons/fa';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import { useNavigate } from "react-router-dom";
import Popover from "@mui/material/Popover";
import LoginPopup from "../LoginSignup/LoginPopup";
import SignupPopup from "../LoginSignup/SignupPopup";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import "react-chat-elements/dist/main.css"
import { MessageBox } from "react-chat-elements";
import Badge from '@mui/material/Badge';
import CloseIcon from '@mui/icons-material/Close';
import { List, ListItem, ListItemAvatar, Avatar, ListItemText, Divider } from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import { InputAdornment, Input, Paper, ListItemIcon } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Config, { IMAGES_URL } from "../Services/Config";
import TextField from "@mui/material/TextField";
import SendIcon from '@mui/icons-material/Send';
import QuestionLoginPopup from "../LoginSignup/QuestionLoginPopup";
import SignOutPopup from "../LoginSignup/SignOutPopup";
import { Accessibility, HomeIcon, Lamp, LogInIcon } from "lucide-react";
import { AddHome, BusinessCenter } from "@mui/icons-material";
import DialogActions from '@mui/material/DialogActions';
import ReactFlagsSelect from "react-flags-select";
import { TextRevealCard, TextRevealCardTitle } from "../Components/ui/text-reveal-card";
import ReactCountryFlag from "react-country-flag"
const ObserverContext = createContext();


function BitcoinIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M8 1.2C5.4 1.2 3.3 3.3 3.3 5.9c0 1.7 1.1 3.3 2.6 3.9v1.5h-1.4c-.4 0-.7.3-.7.7s.3.7.7.7h1.4v7h-1.2c-.4 0-.7.3-.7.7s.3.7.7.7h1.2v1.4h1.5V13h1.5c.4 0 .7-.3.7-.7s-.3-.7-.7-.7h-1.5V9.8c1.5-.6 2.6-2.2 2.6-3.9 0-2.6-2.1-4.7-4.7-4.7zm0 6.8c-1.3 0-2.4-.9-2.8-2.1h5.7c-.4 1.2-1.5 2.1-2.9 2.1z" />
    </SvgIcon>
  );
}

function ObserverProvider({ children }) {
  const observers = useRef(new Set());

  const addObserver = (observer) => {
    observers.current.add(observer);
  };

  const removeObserver = (observer) => {
    observers.current.delete(observer);
  };

  const notifyObservers = (data) => {
    observers.current.forEach((observer) => observer(data));
  };

  return (
    <ObserverContext.Provider value={{ addObserver, removeObserver, notifyObservers }}>
      {children}
    </ObserverContext.Provider>
  );
}

function useObserver() {
  return useContext(ObserverContext);
}
export { ObserverProvider, useObserver };

function Navbar({ openDialog , handleSetOpenDialog, handleSetCloseDialog, hostId, hostName, hostMsg , handleChangeLanguage }) {
  const [isScrolled, setIsScrolled] = useState(false);
  const { addObserver, removeObserver, notifyObservers } = useObserver();
  const [loginOpen, setLoginOpen] = useState(false);
  const [questionLogin, setQuestionLogin] = useState(false);
  const [questionSignOut, setQuestionSignOut] = useState(false);
  const [signupOpen, setSignupOpen] = useState(false);
  const [backgroundAnimated, setBackgroundAnimated] = useState(false);
  const { isAuthenticated, logout, userDetails } = useAuth();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('xl');
  const [AllChats, setAllChats] = useState([]);
  const [AllMsgs, setAllMsgs] = useState([]);
  const [user_id, setUserId] = useState();
  const [usernameLR, setUsernameSend] = useState([]);
  const [message, setMessage] = useState('');
  const chatContainerRef = useRef(null);
  const lastMessage = AllMsgs && AllMsgs.length > 0 ? AllMsgs[0] : null;
  const isCurrentUserSender = lastMessage && lastMessage.user_id === user_id;
  const [allLastMessages, setAllLastMessages] = useState({});
  const [selected, setSelected] = useState("US");
  const [lang, setLang] = useState("en");
  const [lang1, setLang1] = useState("0");
  const [anchorElIcon, setAnchorElIcon] = useState(null);
  const openIcon = Boolean(anchorElIcon);
  const [loadd, setLoadd] = useState("0");
  // Effect to update usernameLR when hostName is available
  useEffect(() => {
    
    if (hostId > 0 && hostId !== true ) {
      
      
      handleListItemClick(hostId,hostName,hostMsg);
      // setAllMsgs([]);
      // setUserId(hostId); 
      // setUsernameSend([hostName, "me"]); // Set usernameLR to hostName
    }
    // if (hostId && hostMsg) {
    //   setAllMsgs([]);
    //   setAllMsgs(hostMsg); // Set usernameLR to hostName
    // }
  }, [hostId, hostName]); // Dependencies to track changes
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [AllMsgs]);

  useEffect(() => {
    const fetchAllChats = async () => {
      try {
        // Fetch all chats
        const data = await Config.getallchats(1);
        
        
        setAllChats(data);
        
        if (data.length > 0) {
          const firstChat = data[0];
          // Handle the first chat
          
          handleListItemClick(firstChat.user.id, firstChat.user.name, firstChat);
        }
  
        // Fetch last messages for each chat
        const lastMessagesForChats = {};
        for (const chat of data) {
          const messages = await Config.getallmessages(chat.user.id);
          lastMessagesForChats[chat.user.id] = messages.length > 0 ? messages[0] : null;
        }
  
        // Update the state with the last messages
        setAllLastMessages(lastMessagesForChats || {});
      } catch (error) {
        // Log any errors
        console.log(error);
      }
    };
  
    // Invoke the async function
    fetchAllChats();
  }, []);
  const switchToSignup = () => {
    handleLoginClose();
    handleSignupOpen();
  };

  const switchToLogin = () => {
    handleSignupClose();
    handleLoginOpen();
  };

  const handleLogout = () => {
    logout();
    navigate("/");
    window.location.reload();
  };

  const handleQuestionLoginClose = () => {
    setQuestionLogin(false);
  };
  useEffect(() => {
    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth);
    };

    // Attach event listener
    window.addEventListener('resize', updateWindowWidth);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

    const handleMenuClickIcon = (event) => {
      setAnchorElIcon(event.currentTarget); // Opens the menu
    };

    const handleMenuCloseIcon = () => {
      setAnchorElIcon(null); // Closes the menu
    };

    const handleLoginIcon = () => {
      // Logic for login
      handleMenuCloseIcon(); // Close the menu when login is selected
      handleLoginOpen();
    };

    const handleSignupIcon = () => {
      // Logic for signup
      handleMenuCloseIcon(); // Close the menu when signup is selected
      handleSignupOpen();
    };

  const [openSubscriptionDialog, setOpenSubscriptionDialog] = useState(false);
  const [openLanguageDialog, setOpenLanguageDialog] = useState(false);
  const handleMenuClick = async () => {
    handleCloseMenu();
    try {
      const userDetails = await Config.getUserDetails();
      // console.log('User Details:', userDetails);
  
      // Ensure the value is an integer
      // const isSubscribed = parseInt(userDetails.data.is_subscribed, 10);
      // if (isSubscribed === 1) {
      //   navigate('/owner');
      // } else {
      //   navigate('/owner');
      //   //setOpenSubscriptionDialog(true);
      // }
      navigate('/owner');
    } catch (error) {
      console.error('Error while handling menu click:', error);
      setOpenSubscriptionDialog(true); // Optionally show dialog if there's an error
    }
  };
  
  const handleSubscriptionDialogClose = () => {
    setOpenSubscriptionDialog(false);
  };
  const handleLanguageDialogClose = () => {
    setOpenLanguageDialog(false);
  };
  
  const handleSubscribeClick = () => {
    // Redirect to subscription page or handle subscription logic
    window.location.href = '/subscribe'; // Adjust URL as needed
  };
  const handleLanguageClick = () => {
    handleCloseMenu();
    setOpenLanguageDialog(true);
  };
  const handleCryptoClick = () => {
    window.location.href = '/crypto'; // Navigate to /crypto

  };
  const handleFacilityClick = () => {
    window.location.href = '/FacilityManagement'; // Navigate to /FacilityManagement
  };
  const handleContactClick = () => {
    window.location.href = '/contact'; // Navigate to /contact
  };
  const handleButtonClick = async () => {
    try {
      const userDetails = await Config.getUserDetails();
  
      // Ensure the value is an integer
      const isSubscribed = parseInt(userDetails.data.is_subscribed, 10);
  
      if (isSubscribed === 1) {
        navigate('/owner');
      } else {
        navigate('/owner');
        // setOpenSubscriptionDialog(true);
      }
    } catch (error) {
      console.error('Error while handling button click:', error);
      setOpenSubscriptionDialog(true); // Optionally show dialog if there's an error
    }
  };

  // const [openDialog, setOpenDialog] = useState(false);

  const handleQuestionSignOutOpen = () => {
    setQuestionSignOut(true);
  }

  const handleQuestionSignOutClose = () => {
    setQuestionSignOut(false);
  }

  const handleQuestionLoginToLoginPopup = () => {
    setQuestionLogin(false);
    handleLoginOpen();
  }

  const handleLoginOpen = () => {
    handleCloseMenu();
    setLoginOpen(true);
    setSignupOpen(false);
  };

  const handleLoginClose = () => {
    setLoginOpen(false);
    setBackgroundAnimated(false);
    // console.log("close login");
  };
  const setLanguage = (code) => {
    handleLanguageDialogClose();    
    setLang(code);
    handleChangeLanguage(code);

  }
   
  const handleSignupOpen = () => {
    handleCloseMenu();
    setLoginOpen(false);
    setSignupOpen(true);
  };

  const handleSignupClose = () => {
    setSignupOpen(false);
    setBackgroundAnimated(true);
  };

  const handleListItemClick = async (userId, usernamee,data) => {
    
    if (isAuthenticated) {
        setAllMsgs([]);
        setUserId(userId);
        setUsernameSend([usernamee, "me"]);
        data.unread_count = 0;  
        const res = await Config.updateUnreadMessaged(userId);

        loadMessagesAndUpdateLastMessage(userId, usernamee);
        try {
          const data = await Config.getallmessages(userId);
          setAllMsgs(data);
        } catch (error) {
          console.log(error);
        }
        
      } else {
        
        handleLoginOpen();
      }    
    
   
    
    
  };
  const handleListItemClickk = async (userId, usernamee,data) => {
    
    if (isAuthenticated) {
        
        setUserId(userId);
        setUsernameSend([usernamee, "me"]);
        data.unread_count = 0;  
        const res = await Config.updateUnreadMessaged(userId);

        loadMessagesAndUpdateLastMessage(userId, usernamee);
        try {
          const data = await Config.getallmessages(userId);
          setAllMsgs([]);
          setAllMsgs(data);
        } catch (error) {
          console.log(error);
        }
        
      } else {

        handleLoginOpen();
      }    
    
   
    
    
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    handleCloseMenu();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    const handleResize = () => {
      if (open) {
        handleClose();
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [open]);

  useEffect(() => {
    let prevScrollY = window.scrollY;
    let timeout;

    const handleScroll = () => {
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        const currentScrollY = window.scrollY;
        if (currentScrollY > prevScrollY && currentScrollY > 100) {
          setIsScrolled(true);
        } else if (currentScrollY < prevScrollY) {
          setIsScrolled(false);
        }
        prevScrollY = currentScrollY;
      }, 15);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function handleBecomeOwner() {
    let token = localStorage.getItem('token');
    console.log(token)
    if (token !== null) {
      navigate('/become-an-owner');
    } else {
      // setLoginOpen(true);
      setQuestionLogin(true);
    }
  }

  const [anchorElMenu, setAnchorElMenu] = React.useState(null);
  const openMenu = Boolean(anchorElMenu);

  useEffect(() => {
    const handleResize = () => {
      if (openMenu) {
        handleCloseMenu();
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [openMenu]);

  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const handleClickOpenDialog = () => {
    handleCloseMenu();
    handleSetOpenDialog(true);
  };
  

  const handleCloseDialog = () => {
    handleSetCloseDialog(false);
    // setOpenDialog(false);
  };

const handleSendMessage = async (e) => {
  e.preventDefault();

  try {
    const data = await Config.send_msg(message, user_id);

    setAllMsgs((prevMsgs) => {
      const updatedMessages = [...prevMsgs];
      const userIdStr = user_id.toString(); // Convert to string

      const chatIndex = updatedMessages.findIndex((msg) => msg.user_id === userIdStr);
      
      
      if (chatIndex !== -1) {
        updatedMessages[chatIndex] = data;
      } else {
        // updatedMessages.push(data);
        updatedMessages.unshift(data);
      }
      
      return updatedMessages;
    });

    const emptyMessage = "";
    setMessage(emptyMessage);

    notifyObservers({
      user_id: user_id.toString(),
      body: message,
      created_at: new Date(),
      username: userDetails?.username || 'Me',
    });
    handleListItemClickk(user_id,userDetails?.username,[]);

  } catch (error) {
    console.error(error);
  }
};


  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };
  
  const loadMessagesAndUpdateLastMessage = async (userId, usernamee) => {
    try {
      const messages = await Config.getallmessages(userId);
      setAllMsgs(messages);

      setAllLastMessages((prevLastMessages) => ({
        ...prevLastMessages,
        [userId]: messages.length > 0 ? messages[0] : null,
      }));

    } catch (error) {
      console.error(error);
    }
  };

 

 

  const handleOpenChatDialog = () => {
    
    if (isAuthenticated) {
      if (user_id) {
        //handleListItemClick(user_id, usernameLR[0]);
        const lastMessageForChat = allLastMessages[user_id];
        if (lastMessageForChat) {
          notifyObservers(lastMessageForChat);
        }
      }
      
      handleSetOpenDialog(true);
    } else {
      handleLoginOpen();
    }
  };

  useEffect(() => {
    const handleObserverUpdate = (data) => {
      setAllLastMessages((prevUserMessages) => {
        const updatedUserMessages = { ...prevUserMessages };

        if (updatedUserMessages[data.user_id]) {
          updatedUserMessages[data.user_id] = data;

          updatedUserMessages[data.user_id].hasNewMessage = true;
        } else {
          updatedUserMessages[data.user_id] = { ...data, hasNewMessage: true };
        }

        return updatedUserMessages;
      });

      setAllMsgs((prevMsgs) => {
        const updatedMessages = [...prevMsgs];
        const chatIndex = updatedMessages.findIndex((msg) => msg.user_id === data.user_id);

        if (chatIndex !== -1) {
          updatedMessages[chatIndex] = data;
        } else {
          // updatedMessages.push(data);
          updatedMessages.unshift(data);
        }

        return updatedMessages;
      });
    };

    addObserver(handleObserverUpdate);

    return () => {
      removeObserver(handleObserverUpdate);
    };
  }, [addObserver, removeObserver, setAllLastMessages, setAllMsgs]);

  useEffect(() => {
    // Ensure Google Translate script is loaded
    const intervalId = setInterval(() => {
      const selectElement = document.querySelector("select.goog-te-combo");
      if (selectElement) {
        console.log("Google Translate widget initialized");
        const iframee = document.getElementById(':1.container'); // Locate the iframe
        if(iframee){
          setLoadd("1");
          iframee.style.height = '0px';
        }
        clearInterval(intervalId);
      }
    }, 500); // Check every 500ms

    return () => clearInterval(intervalId);
  }, []);
 

  useEffect(() => {
    console.log("lang", lang);
    

    // Change the language using the widget's API
    const selectElement = document.querySelector('select.goog-te-combo'); // Find the language selector
    if (selectElement) {
      console.log("select");
      console.log("loadl", loadd);
      
      if (lang === 'en' && loadd == "0") {
        console.log("here");
        
        // Reset language by triggering the "restore" button click
        const iframe = document.getElementById(':1.container'); // Locate the iframe
        if (iframe) {
          console.log("AAAAAA");

          const iframeDoc = iframe.contentDocument || iframe.contentWindow.document; // Access the iframe's document
          const restoreButton = iframeDoc.getElementById(':1.restore'); // Find the restore button by ID
          if (restoreButton) {
            console.log("xxxxx");
            restoreButton.click(); // Simulate a click to reset translation
            console.log("Restore button clicked");
          } else {
            console.log("Restore button not found");
          }
        } else {
          console.log("Google Translate iframe not found");
        }
      } else {
        console.log("no here");

        // Set the desired language (e.g., 'ar')
        selectElement.value = lang;

        // Trigger the change event
        const event = new Event('change', { bubbles: true });
        selectElement.dispatchEvent(event);

        const iframee = document.getElementById(':1.container'); // Locate the iframe
        if(iframee){
          iframee.style.height = '0px';
        }
        
        // Call the delayed reload function
        // delayedReload();
      }
      if(lang == "ar"){
        setLang1(lang);
      }
      
    }
  }, [lang]); // Dependencies ensure this runs only when `lang` or `lang1` change.


  useEffect(() => {
    if(lang1 == "en"){
      return;
    }
    if (lang1 !== "0" && lang1 !=="en") {
      // setTimeout(() => {
      //   window.location.reload(); // Refresh the page after 200ms
      // }, 200);
      
    }
  
  }, [lang1]);
  const delayedReload = () => {
    console.log("Reloading page in 200ms...");
    // Blur any focused elements before reloading
    const focusedElement = document.activeElement;
    if (focusedElement) {
      focusedElement.blur(); // Remove focus to avoid issues on reload
    }
    setTimeout(() => {
      window.location.reload(); // Refresh the page after 200ms
      console.log("Page reloaded.");
    }, 200);
  };
  return (
    
    <nav className={`navbar ${isScrolled ? "navbar-scrolled" : ""}`}>
       
      <div className="logo ">
        <a href="/">
          <img
            src="/LOGO-divinests-red.png"
            alt="logo"
          />
        </a>
      
      </div>
      
      

      <ul className="nav-links">

        

        {windowWidth > 800 && (
          <>

          {isAuthenticated ? (
               <div className="nav-btns">
                <Button
                  variant="outlined"
                  onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#bb0129")}
                  onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "")}
                  startIcon={<NotificationsIcon fontSize="small" />}
                  className="nav-btn"
                  onClick={() => handleOpenChatDialog()}
                  sx={{ borderRadius: '8px', textTransform: 'none' }}
                >
                  Notifications
                </Button>

                      
              </div>
              )
              : null
               }
              {isAuthenticated ? (
              <div className="nav-btns">
                {userDetails && userDetails.type === "3" ? (
                      <Button
                      variant="outlined"
                      onClick={handleButtonClick}
                      className="nav-btn"
                      startIcon={<BusinessCenter />}
                    >
                      Owner Page
                    </Button>
                ) : (
                  <Button
                    variant="outlined"
                    onClick={handleBecomeOwner}
                    className="nav-btn"
                    startIcon={<AddHome style={{height:'20px'}}/>}

                  >
                    Become an Owner
                  </Button>
                )}


              </div>
              )
              : null
               }
              
                <div className="nav-btns">
                     <a href="/crypto" style={{ textDecoration: 'none' }}>
                      <Button
                          variant="outlined"
                          className={`nav-btn `}
                          startIcon={<CurrencyBitcoinIcon  sx={{ fontSize: 20 ,   marginLeft: 1 }} />}

                        >
                          Crypto Currency
                        </Button>
                        </a>
                </div>
               
              <div className="nav-btns">
              
                       <a href="/FacilityManagement" style={{ textDecoration: 'none' }}>
                      <Button
                          variant="outlined"
                          className={`nav-btn `}
                          startIcon={<BusinessCenterIcon   sx={{ fontSize: 20 ,   marginLeft: 1 }} />}

                        >
                          Facility Management
                        </Button>
                        </a>
                </div>
                {!isAuthenticated ? (
                     <div className="nav-btns">
                        <a href="/contact" style={{ textDecoration: 'none' }}>

                        <Button
                            variant="outlined"
                            className={`nav-btn `}
                            startIcon={<PhoneIcon   sx={{ fontSize: 20,  marginLeft: 1  }} />}

                          >
                            Contact Us
                          </Button>
                          </a>
                      </div>
                    )
                    : null
                    }
                    <div className="nav-btns">
                        <Button
                            variant="outlined"
                            onClick={handleLanguageClick}
                            className={`nav-btn ${openLanguageDialog ? 'nav-btn-active' : ''}`}

                          >
                            <LanguageIcon   sx={{ fontSize: 25 }} />
                          </Button>
                    </div>


                
               
              {isAuthenticated ? (
                  <div className="nav-btns">
                    <Button
                      variant="outlined"
                      onClick={handleClick}
                      // startIcon={<PersonIcon fontSize="small" style={{}} />}
                      className={`nav-btn nav-btn-active`}
                    >
                      
                      <MenuIcon sx={{ fontSize: 25 }} style={{ marginRight: '10px' }} />
                      <PersonIcon sx={{ fontSize: 25 }} />
                    </Button>
                  </div>
                ) : (
                  <div className="nav-btns">
                    <Button
                      variant="outlined"
                      onClick={handleMenuClickIcon}
                      className={`nav-btn ${openIcon ? 'nav-btn-active' : ''}`}
                      
                    >
                      <MenuIcon sx={{ fontSize: 25 }} style={{ marginRight: '10px' }} />
                      <PersonIcon sx={{ fontSize: 25 }} />
                    </Button>
                      {/* Dropdown Menu */}
                      <div className="nav-btns" >
                          <Menu
                            anchorEl={anchorElIcon}
                            open={Boolean(anchorElIcon)}
                            onClose={handleMenuCloseIcon}
                          >
                            <Typography sx={{ p: 1, width: "150px" }}>

                              <Button className="nav-btn1 nav-btn-left" onClick={handleLoginIcon} startIcon={<AccountBoxOutlinedIcon sx={{ fontSize: 25 }} />}>Login</Button>
                              <Button className="nav-btn1 nav-btn-left" onClick={handleSignupIcon} startIcon={<LoginIcon sx={{ fontSize: 25 }}/>}>Sign Up</Button>
                            </Typography>
                          </Menu>
                      </div>
                      
                        
                  </div>
                )}

            
              
          </>
        )}

        {windowWidth <= 800 && (
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClickMenu}
            sx={{
              backgroundColor: '#fff',
              '&:hover': {
                backgroundColor: 'rgba(238, 238, 238, 0.5)',
              },
            }}
          >
            <MenuIcon fontSize="large" style={{ color: "#bb0129" }} />
          </Button>
          
        )}
                <Dialog open={openSubscriptionDialog} onClose={handleSubscriptionDialogClose}>
        <DialogTitle>Required Pay</DialogTitle>
        <DialogContent>
          <p>You should pay 100$ per year.</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubscribeClick} color="primary">
            Pay
          </Button>
          <Button onClick={handleSubscriptionDialogClose} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

        <Menu
          id="basic-menu"
          anchorEl={anchorElMenu}
          open={openMenu}
          onClose={handleCloseMenu}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          style={{ marginLeft: "0%" ,zIndex: 2000 }}
        >
          {windowWidth < 800 && [
              
              !isAuthenticated ? (
                // <div className="nav-btns">
                   [
                   <MenuItem  className="nav-btn1 nav-btn-left" key="login" onClick={handleLoginOpen}>
                      <AccountBoxOutlinedIcon sx={{ fontSize: 25,  marginRight: 1 }} /> Login
                   </MenuItem>,

                   <MenuItem className="nav-btn1 nav-btn-left" key="login" onClick={handleSignupOpen}  >
                      <LoginIcon sx={{ fontSize: 25,  marginRight: 1 }}/> Sign Up
                   </MenuItem>
                   ,

                   <MenuItem className="nav-btn1 nav-btn-left" key="language" onClick={handleLanguageClick}  >
                      <LanguageIcon sx={{ fontSize: 25,  marginRight: 1 }}/> Language
                   </MenuItem>,

                  <MenuItem className="nav-btn1 nav-btn-left" key="language" onClick={handleCryptoClick}  >
                    <CurrencyBitcoinIcon sx={{ fontSize: 25,  marginRight: 1 }}/> Crypto Currency
                  </MenuItem>,
                  <MenuItem className="nav-btn1 nav-btn-left" key="language" onClick={handleFacilityClick}  >
                    <BusinessCenterIcon sx={{ fontSize: 25,  marginRight: 1 }}/> Facility Management
                  </MenuItem>,
                  <MenuItem className="nav-btn1 nav-btn-left" key="language" onClick={handleContactClick}  >
                    <PhoneIcon sx={{ fontSize: 25,  marginRight: 1 }}/> Contact Us
                  </MenuItem>,
                   
                
                   ]
                  
                // </div>
                
                


              ) : (
                [
                  <MenuItem key="notifications" className="nav-btn1 nav-btn-left" onClick={handleClickOpenDialog}  >
                    <NotificationsIcon sx={{ fontSize: 25, marginRight: 1 }} /> Notifications
                  </MenuItem>,
                  <MenuItem key="ownerPage" className="nav-btn1 nav-btn-left" onClick={handleMenuClick}>
                    <BusinessCenter sx={{ fontSize: 25, marginRight: 1 }} /> Owner Page
                  </MenuItem>,

                  <MenuItem key="profile" className="nav-btn1 nav-btn-left" onClick={() => navigate("/profile")}  >
                     <AccountBoxOutlinedIcon sx={{ fontSize: 25, marginRight: 1 }} /> Profile

                  </MenuItem>,
                  <MenuItem className="nav-btn1 nav-btn-left" onClick={() => navigate("/billing")} >
                     <CardTravelIcon sx={{ fontSize: 25, marginRight: 1 }} /> Billing
                  </MenuItem>,
                  <MenuItem className="nav-btn1 nav-btn-left" onClick={handleQuestionSignOutOpen}>
                    <LogoutIcon sx={{ fontSize: 25, marginRight: 1 }} /> Logout
                  </MenuItem>,
                   <MenuItem className="nav-btn1 nav-btn-left" key="language" onClick={handleLanguageClick}  >
                     <LanguageIcon sx={{ fontSize: 25,  marginRight: 1 }}/> Language
                   </MenuItem>,
                   <MenuItem className="nav-btn1 nav-btn-left" key="language" onClick={handleCryptoClick}  >
                      <CurrencyBitcoinIcon sx={{ fontSize: 25,  marginRight: 1 }}/> Crypto Currency
                    </MenuItem>,
                    <MenuItem className="nav-btn1 nav-btn-left" key="language" onClick={handleFacilityClick}  >
                      <BusinessCenterIcon sx={{ fontSize: 25,  marginRight: 1 }}/> Facility Management
                    </MenuItem>,
                    <MenuItem className="nav-btn1 nav-btn-left" key="language" onClick={handleContactClick}  >
                      <PhoneIcon sx={{ fontSize: 25,  marginRight: 1 }}/> Contact Us
                    </MenuItem>,
                                    
                ]
              ),

            // <MenuItem key="notifications" onClick={handleClickOpenDialog}>
            //   Notifications
            // </MenuItem>,
            // !isAuthenticated && (
            //   <MenuItem key="becomeOwner" onClick={handleBecomeOwner}>
            //     Become an Owner
            //   </MenuItem>
            // ),
            // !isAuthenticated ? (
            //   <MenuItem key="login" onClick={handleLoginOpen}>
            //     Login
            //   </MenuItem>
              


            // ) : (
            //   [
            //     <MenuItem key="ownerPage" onClick={handleMenuClick}>
            //     Owner Page
            //   </MenuItem>,

                
            //     <MenuItem key="account" onClick={handleClick}>
            //       Account
            //     </MenuItem>
            //   ]
            // ),

            //  <MenuItem key="ownerPage" onClick={handleMenuClick}>
            //     Owner Page
            //   </MenuItem>,
            
          ]}
        </Menu>

        <div className="nav-btns">
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Typography sx={{ p: 1, width: "150px" }}>
              <Button className="nav-btn1 nav-btn-left" onClick={() => navigate("/profile")} startIcon={<AccountBoxOutlinedIcon></AccountBoxOutlinedIcon>}>Profile</Button>
              <Button className="nav-btn1 nav-btn-left" onClick={() => navigate("/billing")} startIcon={<CardTravelIcon></CardTravelIcon>}>Billing</Button>
              {/* <Button className="nav-btn1 nav-btn-left" onClick={handleLogout} startIcon={<LogoutIcon></LogoutIcon>}>Logout</Button> */}
              <Button className="nav-btn1 nav-btn-left" onClick={handleQuestionSignOutOpen} startIcon={<LogoutIcon></LogoutIcon>}>Logout</Button>
            </Typography>
          </Popover>
        </div>

        <div className="nav-btns">

            <Dialog open={openLanguageDialog} fullWidth={false} 
                      maxWidth="md"  onClose={handleLanguageDialogClose}>
                  <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h2>Language</h2>
                    <Button 
                      onClick={handleLanguageDialogClose} 
                      sx={{ minWidth: 0, padding: 0 }}
                    >
                      <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>×</span>
                    </Button>
                  </DialogTitle>
                  <DialogContent >
                    {/* <h2> Language </h2> */}
                    <h2>Choose a language and region:</h2>
                    <br/><br/>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'flex-start' }} >

                    
                      <Button className="nav-btn nav-btn-left" onClick={() => setLanguage("en")} startIcon={<ReactCountryFlag countryCode="US" svg />} sx={{ minWidth: '120px' }}  >English</Button>
                      <Button className="nav-btn nav-btn-left" onClick={() => setLanguage("ar")} startIcon={<ReactCountryFlag countryCode="SA" svg /> } sx={{ minWidth: '120px' }}  >Arabic</Button>
                      <Button className="nav-btn nav-btn-left" onClick={() => setLanguage("fr")} startIcon={<ReactCountryFlag countryCode="FR" svg /> } sx={{ minWidth: '120px' }}  >French</Button>
                      <Button className="nav-btn nav-btn-left" onClick={() => setLanguage("es")} startIcon={<ReactCountryFlag countryCode="ES" svg /> } sx={{ minWidth: '120px' }}  >Spanish</Button>
                      <Button className="nav-btn nav-btn-left" onClick={() => setLanguage("de")} startIcon={<ReactCountryFlag countryCode="DE" svg /> } sx={{ minWidth: '120px' }}  >German</Button>
                    </div>
                    <br/><br/>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'flex-start' }} >
                      <Button className="nav-btn nav-btn-left" onClick={() => setLanguage("it")} startIcon={<ReactCountryFlag countryCode="IT" svg /> } sx={{ minWidth: '120px' }}  >Italian</Button>
                      <Button className="nav-btn nav-btn-left" onClick={() => setLanguage("zh-CN")} startIcon={<ReactCountryFlag countryCode="CN" svg /> } sx={{ minWidth: '120px' }}  >Chinese</Button>

                      {/* Adding more languages below */}
                        <Button
                          className="nav-btn nav-btn-left"
                          onClick={() => setLanguage("ru")}
                          startIcon={<ReactCountryFlag countryCode="RU" svg />}
                          sx={{ minWidth: '120px' }}
                        >
                          Russian
                        </Button>

                        <Button
                          className="nav-btn nav-btn-left"
                          onClick={() => setLanguage("ja")}
                          startIcon={<ReactCountryFlag countryCode="JP" svg />}
                          sx={{ minWidth: '120px' }}
                        >
                          Japanese
                        </Button>
                        <Button
                          className="nav-btn nav-btn-left"
                          onClick={() => setLanguage("fi")}
                          startIcon={<ReactCountryFlag countryCode="FI" svg />}
                          sx={{ minWidth: '120px' }}
                        >
                          Finnish
                        </Button>

                        </div>
                        <br/><br/>
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'flex-start' }} >
                        <Button
                          className="nav-btn nav-btn-left"
                          onClick={() => setLanguage("ko")}
                          startIcon={<ReactCountryFlag countryCode="KR" svg />}
                          sx={{ minWidth: '120px' }}
                        >
                          Korean
                        </Button>

                        <Button
                          className="nav-btn nav-btn-left"
                          onClick={() => setLanguage("hi")}
                          startIcon={<ReactCountryFlag countryCode="IN" svg />}
                          sx={{ minWidth: '120px' }}
                        >
                          Hindi
                        </Button>

                        <Button
                          className="nav-btn nav-btn-left"
                          onClick={() => setLanguage("th")}
                          startIcon={<ReactCountryFlag countryCode="TH" svg />}
                          sx={{ minWidth: '120px' }}
                        >
                          Thai
                        </Button>

                        <Button
                          className="nav-btn nav-btn-left"
                          onClick={() => setLanguage("tr")}
                          startIcon={<ReactCountryFlag countryCode="TR" svg />}
                          sx={{ minWidth: '120px' }}
                        >
                          Turkish
                        </Button>

                      

                        <Button
                          className="nav-btn nav-btn-left"
                          onClick={() => setLanguage("nl")}
                          startIcon={<ReactCountryFlag countryCode="NL" svg />}
                          sx={{ minWidth: '120px' }}
                        >
                          Dutch
                        </Button>
                        </div>
                        <br/><br/>
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'flex-start' }} >
                        <Button
                          className="nav-btn nav-btn-left"
                          onClick={() => setLanguage("pl")}
                          startIcon={<ReactCountryFlag countryCode="PL" svg />}
                          sx={{ minWidth: '120px' }}
                        >
                          Polish
                        </Button>

                        <Button
                          className="nav-btn nav-btn-left"
                          onClick={() => setLanguage("sv")}
                          startIcon={<ReactCountryFlag countryCode="SE" svg />}
                          sx={{ minWidth: '120px' }}
                        >
                          Swedish
                        </Button>

                        <Button
                          className="nav-btn nav-btn-left"
                          onClick={() => setLanguage("no")}
                          startIcon={<ReactCountryFlag countryCode="NO" svg />}
                          sx={{ minWidth: '120px' }}
                        >
                          Norwegian
                        </Button>

                        
                        <Button className="nav-btn nav-btn-left" onClick={() => setLanguage("pt")} startIcon={<ReactCountryFlag countryCode="PT" svg /> } sx={{ minWidth: '120px' }}  >Portuguese</Button>

                      </div>
                  </DialogContent>
               
                </Dialog>

            
              <Dialog
                      open={openDialog}
                      fullWidth={fullWidth}
                      maxWidth={maxWidth}
                      onClose={handleCloseDialog}
                      PaperComponent={(props) => (
                        <Paper {...props} sx={{ height: '80vh', width: '80%', borderRadius: '16px' }} />
                      )}
                    >
                      <DialogTitle
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: '16px 24px',
                          backgroundColor: '#f5f5f5',
                          borderBottom: '1px solid #e0e0e0',
                        }}
                      >
                        <Typography variant="h6">
                          Chats with {usernameLR[0]} 
                        </Typography>
                        <IconButton
                          aria-label="close"
                          onClick={handleCloseDialog}
                          sx={{
                            borderRadius: '50%',
                            backgroundColor: '#e0e0e0',
                            '&:hover': {
                              backgroundColor: '#bdbdbd',
                            },
                          }}
                        >
                          <CloseIcon sx={{ color: '#616161' }} />
                        </IconButton>
                      </DialogTitle>

                      <DialogContent
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          height: 'calc(80vh - 64px)', // Adjust for DialogTitle height
                          padding: '0',
                        }}
                      >
                        <Grid container sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                          <Grid item lg={3} sx={{ borderRight: '1px solid #d7d7d7', height: '100%' }}>
                            <div style={{ padding: '16px', borderBottom: '1px solid #e0e0e0' }}>
                              <Paper
                                component="form"
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  width: '100%',
                                  marginBottom: '16px',
                                  padding: '8px',
                                  boxShadow: '0px 1px 4px rgba(0,0,0,0.1)',
                                  borderRadius: '8px',
                                }}
                              >
                                <Input
                                  placeholder="Search"
                                  fullWidth
                                  disableUnderline
                                  sx={{
                                    marginLeft: '8px',
                                    paddingRight: '16px',
                                    '&::placeholder': {
                                      textAlign: 'left',
                                    },
                                  }}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton aria-label="search">
                                        <SearchIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                />
                              </Paper>
                            </div>
                            <List sx={{ height: 'calc(80vh - 130px)', overflowY: 'auto' }}>
                              {AllChats && AllChats.length > 0 ? (
                                AllChats.map((data, index) => {
                                  const lastMessageForChat = allLastMessages[data.user.id];
                                  const hasNewMessage = lastMessageForChat && lastMessageForChat.hasNewMessage;

                                  return (
                                    <React.Fragment key={index}>
                                      <ListItem
                                        button
                                        onClick={() => handleListItemClick(data.user.id, data.user.name, data)}
                                        className={hasNewMessage ? 'new-message' : ''}
                                      >
                                        <ListItemAvatar>
                                          <Avatar alt={data.user.name} src={`${IMAGES_URL}${data.user.profile_pic}`} />
                                        </ListItemAvatar>
                                        <ListItemText
                                          primary={data.user.name}
                                          secondary={
                                            lastMessageForChat
                                              ? lastMessageForChat.body
                                              : "No messages"
                                          }
                                        />
                                        <Badge
                                          badgeContent={data.unread_count}
                                          color="success"
                                          overlap="circular"
                                        />
                                      </ListItem>
                                      {index < AllChats.length - 1 && <Divider />}
                                    </React.Fragment>
                                  );
                                })
                              ) : (
                                <div>No chat found.</div>
                              )}
                            </List>
                          </Grid>

                          <Grid item lg={9} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                            <div ref={chatContainerRef} style={{ flex: 1, overflowY: 'auto', padding: '16px' }}>
                              {[...AllMsgs].reverse().map((data, index, array) => {
                                const messageDate = new Date(data.created_at);
                                const options = { weekday: 'short', month: 'numeric', day: 'numeric', year: 'numeric' };
                                const formattedDate = messageDate.toLocaleDateString(undefined, options);

                                const shouldDisplayDate = index === 0 || (index > 0 && messageDate.toDateString() !== new Date(array[index - 1].created_at).toDateString());

                                return (
                                  <React.Fragment key={index}>
                                    {shouldDisplayDate && (
                                      <div style={{ textAlign: 'center', marginTop: '10px', color: '#888' }}>
                                        {formattedDate}
                                      </div>
                                    )}
                                    <div style={{ marginBottom: '8px' }}>
                                      <MessageBox
                                        position={data.user_id === user_id ? "left" : "right"}
                                        type={"text"}
                                        title={data.user_id === user_id ? "" : ""}
                                        text={data.body}
                                        className={data.isNew ? 'new-message' : ''}
                                      />
                                      <div className="message-date" style={{ textAlign: data.user_id === user_id ? 'left' : 'right', color: '#888' }}>
                                        {messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                      </div>
                                    </div>
                                  </React.Fragment>
                                );
                              })}
                            </div>

                            <div style={{ padding: '8px', backgroundColor: '#f8f8f9', borderTop: '1px solid #e0e0e0', display: 'flex', alignItems: 'center' }}>
                              <TextField
                                placeholder="Type your message"
                                variant="outlined"
                                fullWidth
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                sx={{ marginRight: '8px' }}
                                autoFocus
                                onFocus={(e) => {
                                  const distanceToBottom = window.innerHeight - e.target.getBoundingClientRect().bottom;
                                  if (distanceToBottom < 0) {
                                    window.scrollTo({
                                      top: window.scrollY + distanceToBottom,
                                      behavior: "smooth",
                                    });
                                  }
                                }}
                              />
                              <IconButton
                                color="primary"
                                onClick={handleSendMessage}
                              >
                                <SendIcon />
                              </IconButton>
                            </div>
                          </Grid>
                        </Grid>
                      </DialogContent>
                    </Dialog>
        </div>
      </ul>
        

      <QuestionLoginPopup isOpen={questionLogin} onClose={handleQuestionLoginClose} onClickLogIn={handleQuestionLoginToLoginPopup}/>

      <LoginPopup isOpen={loginOpen} onClose={handleLoginClose} onSwitchToSignup={switchToSignup} />
      

      <SignupPopup isOpen={signupOpen} onClose={handleSignupClose} onSwitchToLogin={switchToLogin} />

      <SignOutPopup isOpen={questionSignOut} onClose={handleQuestionSignOutClose} onClickSignout={handleLogout}/>
 

    </nav>
  );
}

export default function NavbarWithObserver({ openDialog, handleSetOpenDialog, handleSetCloseDialog , hostId , hostName, hostMsg, handleChangeLanguage }) {

  // const handleChangeLanguage = (language) => {
  //   setLangnav(language);
  //   setAppLang(language); // Notify the App about the change
  //   console.log("lan",language);
    
  // };
  return (
    
    <ObserverProvider openDialog={openDialog} handleSetOpenDialog={handleSetOpenDialog} handleSetCloseDialog={handleSetCloseDialog} hostId={hostId} hostName={hostName} hostMsg={hostMsg} handleChangeLanguage={handleChangeLanguage}>
      <Navbar openDialog={openDialog} handleSetOpenDialog={handleSetOpenDialog} handleSetCloseDialog={handleSetCloseDialog} hostId={hostId} hostName={hostName} hostMsg={hostMsg} handleChangeLanguage={handleChangeLanguage} />
    </ObserverProvider>
  );
}

