import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Load Stripe outside of a component’s render to avoid reinitializing Stripe on every render.
const stripePromise = loadStripe('pk_test_51OY4abH6BZMdwsPwQMYImopDLzLcGaTWwqMGtbvtfORTW7ZQW7LYKiU6XcueaMx9orrDHahRJWgep5AhIZnEOo9J00WR1A0zi7');

const StripeProvider = ({ children }) => {
  return (
    <Elements stripe={stripePromise}>
      {children}
    </Elements>
  );
};

export default StripeProvider;
