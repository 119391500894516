import React, { useState } from "react";
import "./Login2.css";
import axios, { AxiosHeaders } from "axios";
import AuthService from "../Services/AuthService";
import SnackbarComponent from "../SnackBar/SnackBar";
import { Button, TextField, Snackbar, Modal, Box } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import AppleIcon from '@mui/icons-material/Apple';
import EmailIcon from '@mui/icons-material/Email';
import Config from "../Services/Config";
import { useGoogleLogin } from "@react-oauth/google";

const Login = ({ openLoginForm, closeLogInPage, openForgetPassword }) => {
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      //here is the token response log  
      //there for the google signin completed
      console.log("Token Response of googleeeee:", tokenResponse);
      // to fetch the user details you should  use google  
      const userInfo = await fetch("https://www.googleapis.com/oauth2/v3/userinfo", {
        headers: {
          Authorization: `Bearer ${tokenResponse.access_token}`,
        },
      });
      const data = await userInfo.json();
      console.log("User Info:", data);
      // try {
      //   const body = {
      //     name : firstName,
      //     last_name : lastName,
      //     phone_number: phoneNumber,
      //     email: email,
      //     password: password,
      //   };
      //   // console.log("body",body);
      //   AuthService.signupGoogle(body).then(
      //     (response) => {
      //       // console.log(response);
      //       let signUpResponseMessage, severity;
      //       // console.log(response);
      //       if (response.success) {
      //         signUpResponseMessage = "Account Created Successfully";
      //         severity = "success";
      //         setTimeout(() => {
      //           window.location.reload(false);
      //       }, 500);
      //       } else {
      //         signUpResponseMessage = response.message;
      //         severity = "error";
      //       }
  
      //       setOpenSnackbar(true);
      //       setSnackbarMessage(signUpResponseMessage);
      //       setSnackbarSeverity(severity);
  
      //       // console.log(signUpResponseMessage);
      //     },
      //     (error) => {
      //       console.log("Login failed", error);
      //     }
      //   );
      // } catch (error) {
      //   console.error(error);
      // }
      //Now you are able to fetch the google info
    },
    onError: () => {
      console.log("Login Failed");
    },
  });
  const [email, setEmail] = useState("");


  const [password, setPassword] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [resetEmail, setResetEmail] = useState(""); // For Reset Password Email
  const [openResetModal, setOpenResetModal] = useState(false); // Modal State

  var loginResponseMessage;


  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const body = {
        email: email,
        password: password,
      };

      AuthService.login(body).then(
        (response) => {
          let loginResponseMessage, severity;

          if (response.success) {
            loginResponseMessage = "Login Successful";
            severity = "success";
            setTimeout(() => {
              window.location.reload(false);
            }, 500);
          } else {
            loginResponseMessage = response.message;
            severity = "error";
          }

          setOpenSnackbar(true);
          setSnackbarMessage(loginResponseMessage);
          setSnackbarSeverity(severity);

          console.log(loginResponseMessage);
        },
        (error) => {
          console.log("Login failed", error);
        }
      );
    } catch (error) {
      console.error(error);
    }
  };
  const handleSocialLogin = (platform) => {
    console.log(`${platform} login clicked`);
    if (platform === "Google") {
     login()
    } else {
      console.log("There is no another method till  now");
      // for future purposes use switch statement for better logic 
    }
    // Handle social login (Google, Facebook, etc.)
  };
  const logout = async () => {
    if (AuthService.getToken() != null) {
      const response = await AuthService.logout();
      console.log(response.message);
    } else {
    }
  };
  // Open Reset Password Modal
  const openResetPasswordModal = () => {
    setOpenResetModal(true);
  };
  // Handle Reset Password
  const handleResetPassword = async () => {
    if (!resetEmail.trim()) {
      setSnackbarMessage("Please enter your email.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return; // Stop the function execution
    }
    try {
      const response = await Config.resetPass(resetEmail);
      console.log(response);

      if (response.success == "success") {
        setSnackbarMessage("Password reset link sent to your email.");
        setSnackbarSeverity("success");
      } else {
        setSnackbarMessage(response.data.message || "Email not found.");
        setSnackbarSeverity("error");
      }
    } catch (error) {
      setSnackbarMessage("An error occurred. Please try again.");
      setSnackbarSeverity("error");
    }

    setOpenSnackbar(true);
    setOpenResetModal(false);
  };


  return (
    <div className="login-form-container fontfamily">

      <div className="header-container">
        <h5>Log in or sign up</h5>
        <div className="close-btn">
          <button className="close-button" onClick={closeLogInPage}>
            x
          </button>
        </div>
      </div>
      <form onSubmit={handleLogin} className="login-form">
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{ marginBottom: '16px' }}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{ marginBottom: '16px' }}
        />
        <Button className="login-btn fontfamily" type="submit" variant="contained" fullWidth style={{ marginBottom: '16px' }}>
          Log In
        </Button>
      </form>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <label style={{ marginRight: '5px' }} >Forget password?{" "}</label>
        <a href
          onClick={
            openResetPasswordModal}
          style={{ cursor: 'pointer', color: '#0077FF' }}
        >
          reset now
        </a>

      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <label style={{ marginRight: '5px' }} >Don't have an account?{" "}</label>
        <a href
          onClick={
            openLoginForm}
          style={{ cursor: 'pointer', color: '#0077FF' }}
        >
          Signup
        </a>

      </div>

      <div className="separator">
        <span className="line"></span>
        <span className="or">or</span>
        <span className="line"></span>
      </div>
      <div className="social-login-buttons">
        <Button
          variant="outlined"
          color="primary"
          startIcon={<GoogleIcon />}
          fullWidth
          onClick={() => handleSocialLogin('Google')}
          style={{ marginBottom: '8px' }}
        >
          Continue with Google
        </Button>
        {/* <Button
          variant="outlined"
          color="primary"
          startIcon={<FacebookIcon />}
          fullWidth
          onClick={() => handleSocialLogin('Facebook')}
          style={{ marginBottom: '8px' }}
        >
          Continue with Facebook
        </Button>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AppleIcon />}
          fullWidth
          onClick={() => handleSocialLogin('Apple')}
          style={{ marginBottom: '8px' }}
        >
          Continue with Apple
        </Button>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<EmailIcon />}
          fullWidth
          onClick={() => handleSocialLogin('Email')}
        >
          Continue with Email
        </Button> */}
      </div>

      <Snackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMessage}
        autoHideDuration={3000}
      />

      {/* Reset Password Modal */}
      <Modal
        open={openResetModal}
        onClose={() => setOpenResetModal(false)}
        aria-labelledby="reset-password-modal"
      >
        <Box className="modal-box">
          <h3>Reset Password</h3>
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            value={resetEmail}
            onChange={(e) => setResetEmail(e.target.value)}
            style={{ marginBottom: "16px" }}
          />
          <Button
            variant="contained"
            color="primary"
            className="login-btn"
            onClick={handleResetPassword}
            fullWidth
          >
            Send Reset Link
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default Login;
